:root {
  --primary: var(--green) !important;
  --secondary: var(--fadeGreen) !important;
  --green: #22C687 !important;
  --fadeGreen: #4ED19F;
  --bluwishGray: #4e5983;
  --warning: #FF4343 !important;
  --gray: #707070 !important;
  --white: #fff !important;
  --black: #000000 !important;
}

html, body, #root {
  height: 100%;
}

.App {
  font-family: 'Poppins', sans-serif;
  height: 100%;
}


.irrigation-cycle-box {
  width: 214px;
  height: 76px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000015;
  border: 0.23999999463558197px solid #0000004f;
  border-radius: 4px;
  /*opacity: 0.4;*/
  float: right;
}

.green-circle, .red-circle {
  width: 12px;
  height: 12px;
  box-shadow: 0px 2px 2px #0000002F;
  opacity: 1;
  border-radius: 100%;
}

.green-circle {
  background: #02C170 0% 0% no-repeat padding-box !important;
}

.red-circle {
  background: #FF4343 0% 0% no-repeat padding-box !important;
}

.irrigation-cycle-text {
  font: normal normal normal 14px/16px Poppins;
  letter-spacing: 0.03px;
  color: #868DAA;
  opacity: 1;
}

.sa-logo {
  height: 32px;
  cursor: pointer;
  margin-top: 5px;
}

.dunya-logo {
  height: 36px;
  cursor: pointer;
}

#dropdown-custom-components::after, #dropdown-custom-components-bell::after, #dropdown-custom-components-profile::after {
  display: none !important;
}


.sa-modal-box-mobile {
  max-height: 668px !important;
}

.padding-scroll {
  padding: 20px !important;
  overflow-y: scroll !important;
}


.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-container p {
  margin-bottom: 1rem;
}

.error-container pre {
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 0.25rem;
  max-width: 80%;
  overflow: auto;
}

.error-container button {
  background-color: #22C687;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .user-profile {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 9px;
    margin-top: 34px;
    border: 2px solid #22C687;
  }
}


.circle-icon-overlap {

  position: absolute;
  top: 5%;
  left: 50%;
  color: #f81d00;

}


.filter-left-inner .search {
  margin-left: 18px !important;
}

.heading-box {
  height: 60px;
  align-items: center;
  background-color: #22c687d6;
  padding: 20px;
}

.sensor-data-p {
  padding-left: 6px !important;
}

.schedule-display {
  display: flex;
  margin: 10px;
}

.view-label {
  font-size: 14px;
  color: #707070;
}

.view-value {
  font-size: 14px;
  font-weight: 600;
}

.ph-image {
  height: 42px;
  width: 42px;
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.farm-view-heading {
  text-align: center;
  color: #22C687;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  //grid-template-columns: 1fr auto 1fr;
  //grid-template-rows: 20px 0;
  //grid-gap: 20px;
}

.farm-view-heading:before, .farm-view-heading:after {
  content: '';
  flex: 1 1 auto;
  border-bottom: solid 1px #F2F2F2;
}


@media (max-width: 490px) {
  .labelSizeInfo-locationInfo {
    margin-top: 344px !important;
  }
  .multi-select {
    width: 100% !important;
  }
  .labelSize {
    margin-right: 16px !important;
  }
}

@media (max-width: 768px) {
  .irrigation-cycle-box {
    float: initial;
    margin-bottom: 20px;
  }
  .daily-update-filter {
    gap: 10px;
    margin-top: 10px;
    flex-flow: wrap;
  }
  .weather-mobile-click {
    display: flex !important;
  }
  .card-summary-box-dashBoardlocation {
    margin-left: 0px !important;
  }
  .row dashboard-location-detail {
    justify-content: center !important;
    display: flex !important;
  }
  .dashboard-location-detail {
    justify-content: center !important;
    display: flex !important;
  }
  .half-list-container-dashBoardBottom {
    margin-left: 0px !important;
  }
  .summaryReport {
    margin-right: -80px !important;
  }
  .filter-left-inner .search {
    display: none;
  }
  .xindicate .filter-left-inner {
    margin-top: 8px;
  }
  .xindicate .filter-left-inner .form-control {
    justify-content: space-between !important;
  }
  .displaySearch {
    margin-left: 0px !important;
    margin-top: 4px !important;
  }
  .circle-icon-overlap {
    position: absolute;
    top: 5%;
    left: 91%;
    color: #f81d00;
  }
}

.schedule-btn {
  width: 140px;
}

@media (max-width: 575px) {
  .circle-icon-overlap {
    position: absolute;
    top: 5%;
    left: 90%;
    color: #f81d00;
  }
}

@media (max-width: 480px) {
  .labelSizeInfo-houseHold {
    margin-top: 248px !important;
  }
  @media (max-width: 768px) {
    .labelSizeInfo-houseHold {
      margin-top: 248px !important;
    }
  }
  .circle-icon-overlap {
    position: absolute;
    top: 5%;
    left: 86%;
    color: #f81d00;
  }
}

@media (max-width: 420px) {
  .circle-icon-overlap {
    position: absolute;
    top: 5%;
    left: 79%;
    color: #f81d00;
  }
}

@media (max-width: 375px) {
  .circle-icon-overlap {
    position: absolute;
    top: 5%;
    left: 72%;
    color: #f81d00;
  }
}

@media (max-width: 320px) {
  .circle-icon-overlap {
    position: absolute;
    top: 5%;
    left: 71%;
    color: #f81d00;
  }
}

.map-style {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.list-view {
  position: absolute;
  background-color: #F8F8F8;
  bottom: 0;
  width: 100%;
  /*overflow: auto;*/
  transition: 0.4s;
}

.dashboard-main {
  background-color: #F8F8F8;
}

.full-list-view {
  height: 100%;
  padding-top: 133px;
}

.half-list-view {
  height: 50%;
}

.single-card {
  -webkit-box-shadow: 0px 0px 8px #0000000A;
  -moz-box-shadow: 0px 0px 8px #0000000A;
  box-shadow: 0px 0px 8px #0000000A;
  border-radius: 8px;
  margin-bottom: 12px;
  border: 0.23999999463558197px solid #EDEDED;
  background-color: #fff;
}

.view-scroll {
  overflow-x: auto;
  overflow-y: auto;
}

.list-view .single-card {
  overflow: hidden;
}

.single-card-header {
  padding: 16px;
  border-bottom: solid 1px #eee;
}

.single-card-items {
  padding: 16px;
}

.single-card-items-export-data {
  padding-top: 16px;
}

.card-top-border {
  border-top: solid 6px #22C687;
  padding-top: 10px;
}

.card-img-view {
  display: inline-block;
  height: 96px;
  width: 96px;
  border-radius: 8px;
  overflow: hidden;
  vertical-align: middle;
}

.meta-name {
  font: normal normal 12px/21px Poppins;
  letter-spacing: 0px;
  color: #181D33A3;
  font-weight: bold;
}

.card-img {
  height: 96px;
}

.card-header-text {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.card-header-text-top {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.card-header-text .heading {
  font-size: 16px;
  font-weight: 600;
  color: #181D33;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
}

.card-header-text-top .heading {
  font-size: 16px;
  font-weight: 600;
  color: #181D33;
}

.card-header-text .sub-text {
  font-size: 12px;
  color: #181D33;
  opacity: 0.48;
}

.card-header-text-top .sub-text {
  font-size: 12px;
  color: #181D33;
  opacity: 0.48;
}

.card-header-text-2 {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.card-header-text-nf {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  font-size: 16px;
}

.card-header-text-2 .heading {
  font-size: 16px;
  font-weight: 600;
  color: #50565F;
}

.card-header-text-2 .sub-text {
  font-size: 12px;
  color: #50565F;
  opacity: 0.48;
}


.col-xl-4 {
  padding-left: 8px;
  padding-right: 8px;
}


.form-groupYesNo {
  margin-bottom: 0px;
  width: 120px;
  margin-left: -10px;
  margin-top: 6px;
}

.form-participantYesNo {
  width: 180px;
  margin-left: -417px;
}

.household .form-groupYesNo {
  margin-left: 8px;
}

.form-groupYesNoGenderFilter {
  margin-bottom: 0px;
  width: 180px;
  margin-left: -50px;
  margin-top: 6px;
}

.w-164 {
  width: 164px !important;
}

.btn-sa-primary-cashFlow {
  background: #22C687 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1;
  height: 44px;
  color: #fff !important;
  font-size: 14px;
}

.error-image {
  text-align: center;
  margin-bottom: 22px;
  width: 256px;
  height: 256px;
}

.Toastify__toast--success {
  background-color: #22C687 !important;
}

.btn-sa-secondary {
  background: #FFF 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  border: solid 1px #22C687 !important;
  opacity: 1;
  height: 44px;
  color: #22C687 !important;
  font-size: 14px;
}

.edit-btn-color {
  color: #aaa !important;
  border: solid 1px #aaa !important;
  padding-top: 10px !important;
}

.white-background {
  background: #FFF 0% 0% no-repeat padding-box !important;
}


.btn-sa-secondary-dashBoard {
  background: #FFF 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  border: solid 1px #C0C0C0 !important;
  opacity: 1;
  height: 44px;
  color: #B7B7B7 !important;
  font-size: 14px;

}

.btn-sa-secondary-export {
  background: #FF4343 0% 0% no-repeat padding-box !important;
  border-radius: 22px !important;
  border: solid 1px #ffff !important;
  opacity: 1;
  height: 44px;
  color: #ffffff !important;
  font-size: 14px;
  margin-left: 8px;
}

.btn-sa-secondary-list {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border-radius: 22px !important;
  border: solid 0.5px #50565F !important;
  opacity: 1;
  height: 44px;
  font-size: 14px;
  margin-left: 8px;
  box-shadow: 0px 4px 8px #C6CEE930;
}

.btn-sa-secondary-duplicate {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border-radius: 22px !important;
  border: solid 1px #ffff !important;
  opacity: 1;
  height: 44px;
  color: #ffffff !important;
  font-size: 14px;
  margin-left: 8px;
  box-shadow: 0px 4px 8px #C6CEE930;
}

.btn-sa-secondary-memberExportCSV {
  background: #FFF 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  border: solid 1px #22C687 !important;
  opacity: 1;
  height: 37px;
  color: #22C687 !important;
  font-size: 14px;
}

.btn-sa-report {
  background: #FFF 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  border: solid 1px #e5e5ea !important;
  opacity: 1;
  height: 36px;
  color: #8e8e93 !important;
  font-size: 14px !important;
}


.btn-sa-success {
  background: #02C170 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1;
  height: 44px;
  color: #FFF !important;
  font-size: 14px !important;
}

.btn-sa-week {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 0.75px solid #3F44511F !important;
  border-radius: 4px !important;
  opacity: 1;
  height: 44px;
  color: #868DAA !important;
  font-size: 14px !important;
  width: 109px;
  margin-bottom: 1px;
  margin-right: 16px;
}

.btn-sa-week-selected {
  background: #02C170 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1;
  height: 44px;
  color: #FFF !important;
  font-size: 14px !important;
  width: 109px;
  margin-bottom: 1px;
  margin-right: 16px;
}

.btn-sa-text {
  background: #FFF 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1;
  height: 44px;
  font-size: 14px;
}

@media (max-width: 508px) {
  .btn-sa-text-size {
    font-size: 13px;
    width: 85px !important

  }

  .btn-sa-primary-cashFlow {
    font-size: 13px;
    width: 70px !important
  }
}


.m-b-4 {
  margin-bottom: 4px;
}

.m-b-28 {
  margin-bottom: 28px;
}

.p-h-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.description .rbt .show {
  display: none !important;
}


.plot-edit {
  display: flex !important;
  justify-content: flex-end;
}

.m-l-6 {
  margin-left: 6px;
}

.m-l-25 {
  margin-left: 25rem;
}

.add-yield-unit {
  margin-left: 10px;
}

.weather-icon-margin {
  margin-top: 41px;
  margin-left: -5px;
}

.temp-box {
  width: 300px;
  background-color: #E7E8EA71;
  padding: 20px 0 10px;
  text-align: center;
}

.form-controls {
  margin: 50px auto;
}

.alert-input-box {
  display: flex;
  margin-bottom: 40px;
}

.alert-level-box {
  width: 100%;
  background: #22C687 0% 0% no-repeat padding-box;
  height: 50px;
  padding: 14px 10px 0px 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  margin-top: 8px;
}

.plot-alert-heading {
  font: normal normal 600 16px/25px Poppins;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  .add-yield-unit {
    margin-left: 0px;
  }
  .add-yield-quantity {
    margin-bottom: 10px;
  }
  .form-controls, .alert-container {
    flex-direction: column;
  }

  .temp-box {
    width: 100% !important;
  }
  .reset-btn {
    margin-top: 0 !important;
  }
  .alert-input-box {
    flex-direction: column;
  }

  .mobile-view-popup {
    display: flex;
  }

  .mobile-view-popup .sa-popup {
    display: flex;
    align-items: center;
  }
  .not-interest-line {
    width: 75% !important;
  }
  .table-whole {
    width: auto !important;
  }
}

.progress-semi-circle {
  position: relative;
  margin: 4px;
  float: left;
  text-align: center;
}

.barOverflow { /* Wraps the rotating .bar */
  position: relative;
  width: 180px;
  height: 90px; /* Half circle (overflow) */
  margin-bottom: -14px; /* bring the numbers up */
  overflow: hidden;
}

.progress-semi-circle-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 30px solid #ccc;
  border-bottom-color: #22C687;
  transform: rotate(45deg);
  border-right-color: #22C687;
}

.progress-percentage {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #76767B;
}

.pro-0 {
  position: absolute;
  right: 230px;
  bottom: 30px;
}

.pro-100 {
  position: absolute;
  right: 74px;
  bottom: 30px;
}

.semi-circle-txt {
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: #22C687;
}

.semi-txt {
  font-size: 14px;
  line-height: 10px;
}

.dashboard-card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
}

.dashboard-irrigation-container {
  width: 98%;
  height: auto;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 8px;
  background-color: #FFFFFF;
  padding: 24px;
  position: relative;
}

.task-icon-box {
  width: 42.14px;
  height: 43.44px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
}

.bg-light-red {
  background: #FFEADA;
}

.bg-primary-green {
  background: #22C687 !important;
}

.bg-light-green {
  background: #DDF9E4;
}

.bg-fade-green {
  background: #E4F0FF;
}

.progress-monthly {
  background-color: #F1F1F1 !important;
  border-radius: 8px !important;
  height: 9.47px !important;
}

.progress-bar-monthly {
  border-radius: 8px !important;
}

.bg-orange {
  background-color: #FF9800 !important;
}

.color-orange {
  color: #FF9800 !important;
}

.task-percentage {
  font-weight: 600;
  font-size: 23.9789px;
  line-height: 36px;
  color: #696969;
  //margin-left: 20px;
}

.task-type {
  font-size: 14px;
  color: #AEAEAE;
}

.to-do-box {
  height: 60px;
  background: #FFFFFF;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
}

.to-do-num .to-do-txt {
  color: #65676D;
}

.to-do-num {
  font-weight: 500;
  font-size: 18px;
  color: #65676D;
  margin-right: 20px;
}

.to-do-txt {
  font-weight: 600;
  font-size: 14px;
  color: #65676D;
}

.today-alert-box {
  height: 53px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
}

.today-alert-container {
  border-left: 6px solid #FF4343;
  display: flex;
  padding: 0 20px;
  height: 53px;
  align-items: center;
}

.today-alert-txt {
  line-height: 21px;
  color: #000000;
  font-size: 14px;
  margin-left: 14px;
}

.progress-financial {
  background-color: #FF4343 !important;
  border-radius: 0 !important;
}

.financial-txt {
  font-size: 14px;
  line-height: 20px;
  color: #6B7280;
}

.financial-type-income, .financial-type-expense {
  font-size: 10px;
  margin-top: 4px;
  line-height: 16px;
}

.financial-type-income {
  color: #22C687;
}

.financial-type-expense {
  color: #FF4343;
  margin-left: auto;
}

.task-table-heading {
  font-size: 12px;
  line-height: 16px;
  color: #242424;
}

.task-table-txt {
  font-size: 14px;
  line-height: 20px;
  color: #242424;
}

.task-table-img {
  border-radius: 50px;
  margin-right: 10px;
}

.last-irrigation-txt {
  font-size: 14px;
  line-height: 20px;
  color: #2196F3;
}

.last-irrigation-time {
  font-size: 14px;
  line-height: 20px;
  color: #707070;
}

.last-irrigation-duration {
  font-size: 14px;
  line-height: 20px;
  color: #6B7280;
}

@media all and (max-width: 992px) and (min-width: 767px) {
  .alert-container-box {
    width: 155% !important;
  }

  .card-summary-box-location-map {
    width: auto !important;
    height: 360px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1257px) {
  .collapse-navbar a {
    font-size: 12px;
    margin-right: 10px;
  }
  .sa-logo{
    margin-top: 0px;
  }
}

.toggle-title {
  text-align: left;
  font: normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  font-weight: 400;
  display: inline-block;
}

.text-dark-gray {
  color: #757575;
}

.toggle-text-green {
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #22C674;
}

.toggle-active-feather {
  color: #22C674;
}

.toggle-border-bottom {
  border-bottom: 1px solid #F2F2F2;;
}

.toggle-btn-container {
  width: 24px;
  height: 24px;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #F5F5F5;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.h-12 {
  height: 12px;
}

.h-16 {
  height: 16px;
}

.p-16 {
  padding: 16px;
}

.p-12 {
  padding: 12px;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-64 {
  padding-top: 64px !important;
}

.p-t-3 {
  padding-top: 3px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}


.xindicate .filter .form-control {
  width: 88% !important;
}

.single-card-content {
  padding: 10px;
  border-bottom: solid 1px #eee;
  background-color: #FDFDFD;
}

.single-card-content-dashBoardParticipant {
  top: 230px;
  left: 122px;
  width: 196px;
  height: 148px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 8px #C6CEE930;
  border-radius: 5px;
  opacity: 1;
  margin-top: 78px;
}

.single-card-content-dashBoardGroups {
  top: 230px;
  left: 330px;
  width: 196px;
  height: 148px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 8px #C6CEE930;
  border-radius: 5px;
  opacity: 1;
  margin-top: 78px;
  margin-left: 10px;
}

.single-card-content-dashBoardHouseHold {
  top: 391px;
  left: 122px;
  width: 196px;
  height: 148px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 8px #C6CEE930;
  border-radius: 5px;
  opacity: 1;
  margin-top: 78px;
}

.crop-view-heading {
  color: #22C674;
  font: normal normal 600 20px/30px Poppins;
}

.crop-view-sub-heading {
  color: #181D33A3;
  font: normal normal medium 14px/21px Poppins;
  text-transform: capitalize;

}

.crop-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
  padding: 16px;
  margin: 35px 0px;

}

.crop-container .c3-bar-0 {
  fill: #E2E5E9 !important;
}

.crop-container .c3-bar-1 {
  fill: #02C170 !important;
}

.crop-container .c3-bar-2 {
  fill: #FF4343 !important;
}

.crop-container .c3-legend-item-Total {
  display: none !important;
}

.crop-chart-x-label {
  margin-top: -27px;
  margin-bottom: 26px;
  text-align: center;
  font: 11px/22px Poppins;
  letter-spacing: 0px;
  color: #666666;
}

.vertical-switch-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.irrigation-mode-heading {
  text-align: left;
  font: normal normal 14px Poppins;
  font-weight: 400;
  letter-spacing: 0px;
  color: #757575;
}

.form-check-label-irrigation {
  text-align: center;
  font: normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.75;
}

.irrigation-check-container .form-check-input {
  height: 20px;
  width: 20px;
  accent-color: #57a50a;
}

.gray-container {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
}

.crop-hading {
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #282C36;
  text-align: center;
}

.crop-view-yield-value {
  color: #191B1F;
  font: normal normal medium 16px/25px Poppins;
}

.crop-view-card-label {
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #181D33;
}

.crop-view-card-value {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #181D33;
}

.crop-view-task-status {
  text-align: center;
  font: 14px/24px Poppins;
  font-weight: 600;
  letter-spacing: 0px;
  color: #374151;
}

.text-err {
  color: #FF4343 !important;
}

.w-167 {
  width: 167px !important;
}

.crop-disease-text {
  font: 12px/1px Poppins;
  color: #000000;
}

.dotted-divider {
  border-top: 1px dashed #000000;
}

.update-text {
  top: 110px;
  left: 1030px;
  width: 120px;
  height: 20px;
  text-align: center;
  font: bold 16px/16px Poppins;
  letter-spacing: 0px;
  color: #3C3C3C;
  opacity: 1;
  margin-bottom: 10px;
}

.update-text1 {
  top: 110px;
  left: 1030px;
  width: 220px;
  height: 20px;
  text-align: center;
  font: bold 16px/16px Poppins;
  letter-spacing: 0px;
  color: #3C3C3C;
  opacity: 1;
  margin-bottom: 10px;
}

.update-text-previous-notification {
  top: 110px;
  left: 1030px;
  width: 220px;
  height: 20px;
  margin-left: 4px;
  font: bold 16px/16px Poppins;
  letter-spacing: 0px;
  color: #3C3C3C;
  opacity: 1;
  margin-bottom: 10px;
}

@media (max-width: 200px) {
  .dashboard-widget {
    display: none !important;
  }
}

@media (min-width: 320px) {
  .dashboard-widget-mobile {
    display: none !important;
  }
}

.dashboard-widget-mobile {
  justify-content: space-evenly !important;
}

.card-content-heading {
  font-size: 14px;
}

.card-content-status {
  height: 36px;
  width: 100%;
  border-radius: 18px;
  background-color: #0000000A;
  font-size: 14px;
  padding-top: 8px;
  margin-top: 5px;
  text-transform: uppercase;
}

.outOf {
  font-size: 12px;
  text-transform: lowercase;
}

.padding-f {
  padding: 5px 5px 5px 16px;
}

.padding-s {
  padding: 5px;
}

.padding-t {
  padding: 5px 16px 5px 5px;
}

.single-card-footer {
  padding: 10px;
}

.single-card-footer a {
  color: #50565F;
}

.single-card-footer a:hover {
  color: #232529;
  text-decoration: none;
}

.card-footer-btn {
  font-size: 14px;
}

.text-green {
  color: #02C170 !important;
}

.text-yellow {
  color: #FFB258;
}

.text-gray {
  color: #aaa;
}

.text-greenMobile {
  color: #02C170;
}

.text-black {
  color: #000000;
}

.text-blue {
  color: #2196F3;
}

.map-toggle-btn {
  top: 64px;
  width: 100%;
  padding: 16px 0;
  background-color: transparent;
  transition: 0.4s;
  z-index: 9;
  pointer-events: none;
}

.map-toggle-bg {
  height: 100px;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.1));
  /*background-color: #fff;*/
  /*-webkit-box-shadow: 0px 0px 8px #0000000A;*/
  /*-moz-box-shadow: 0px 0px 8px #0000000A;*/
  /*box-shadow: 0px 0px 8px #0000000A;*/
}

.tog-sa-primary {
  background: #22C687 0% 0% no-repeat padding-box !important;
  border-radius: 0;
  border: 0.23999999463558197px solid #EDEDED;
  opacity: 1;
  height: 36px;
  color: #fff !important;
  font-size: 14px !important;
}

.tog-sa-secondary {
  background: #FFF 0% 0% no-repeat padding-box !important;
  border-radius: 0;
  border: 0.23999999463558197px solid #EDEDED;
  opacity: 1;
  height: 36px;
  color: #22C687 !important;
  font-size: 14px !important;
}

.first-tog {
  border-radius: 4px 0px 0px 4px !important;
  width: 105px;
}

.second-tog {
  border-radius: 0px 4px 4px 0px !important;
  width: 105px;
}

.w-124 {
  width: 124px !important;
}

.w-110 {
  width: 110px !important;
}

.dis-in-bl {
  display: inline-block;
}

.m-rl-8-15 {
  margin: 0 8px 0 24px;
}

.m-r-90 {
  margin-right: 90px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t_-2 {
  margin-top: -2px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-b-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-t-1 {
  margin-top: 1px !important;
}

.m-t-3 {
  margin-top: 3px !important;
}

.m-t-16 {
  margin-top: 16px;
  width: -webkit-fill-available;
}
.m-t-16:last-child{
  margin-bottom: 16px;
}

.m-t-26 {
  margin-top: 26px;
}

.m-t-200 {
  margin-top: 200px;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-r-0{
  padding-right: 0 !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.p-r-10{
  padding-right: 10px !important;
}

.p-r-20{
  padding-right: 20px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-b-12 {
  padding-bottom: 12px;
}

.m-l-4 {
  margin-left: 4px;
}

.m-l-19 {
  margin-left: 19px;
  margin-right: 19px;
  margin-top: 19px;
}


.m-l-117 {
  margin-left: 117px;
}

.mr-123 {
  margin-right: 123px;
}

.float-r {
  float: right;
}

.aline {
  text-align: center;
  align-items: center;
}

.plots-header-toggles {
  display: inline-block;
  width: 100%;
  padding: 12px 0;
  font-size: 12px;
  color: #50565F;
  text-transform: uppercase;
  border: 0.23999999463558197px solid #EDEDED;
  cursor: pointer;
  height: 40px;
}
.plots-header-toggles-auto {
  display: inline-block;
  width: 100%;
  padding: 12px 0;
  font-size: 12px;
  color: #50565F;
  text-transform: uppercase;
  border: 0.23999999463558197px solid #EDEDED;
  cursor: pointer;
  height: 40px;
}


.farm-header-toggles {
  display: inline-block;
  width: 100%;
  padding: 12px 0;
  font-size: 12px;
  color: #50565F;
  text-transform: uppercase;
  border: 0.23999999463558197px solid #EDEDED;
  cursor: pointer;
  height: 40px;
}

.dashboard-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  opacity: 1;
  width: 210px;
  height: 157px;
  text-align: center;
  margin: 0 10px 0;
}

.dashboard-icon-circle {
  width: 64px;
  height: 64px;
  border: 1px solid #F2F2F2;
  border-radius: 100%;
  margin: 10px auto;
}

.dashboard-card-price {
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #282C36;
  opacity: 1;
}

.dashboard-card-txt {
  font: normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #A7A7C5;
  font-weight: 600;
}

.dashboard-card-percentage {
  text-align: right;
  font: normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  font-weight: 600;
}

.farm-financial {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
  width: 506px;
  height: 329px;
}

.farm-financial-heading {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #282C36;
  text-transform: capitalize;
}

.dashboard-tasks {
  border: 1px solid #F2F2F2;
  border-radius: 6px;
  width: 670px;
  height: 161px;
}

.dashboard-tasks {
  font: normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #6F6F6F;
  font-weight: 600;
}

.dashboard-tasks-amount {
  font: normal normal 600 45px/68px Poppins;
  letter-spacing: 0px;
  color: #17181C;
}

.dashboard-tasks-details {
  border-radius: 8px;
  width: 133px;
  height: 48px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.dashboard-tasks-red {
  background: #FFB2581F 0% 0% no-repeat padding-box;
  border: 1px solid #FFB258;
}

.dashboard-tasks-blue {
  background: #83E9941F 0% 0% no-repeat padding-box;
  border: 1px solid #22C687;
}

.dashboard-tasks-txt {
  font: normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #757575;
  font-weight: 600;
}

.dashboard-borders {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
  height: 346px;;

}

.dashboard-icon-box {
  width: 56px;
  height: 56px;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.dashboard-threshold-data {
  font-weight: 600;
  font-size: 23.9789px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.75);
}

.dashboard-threshold-txt {
  font-size: 14px;
  line-height: 21px;
  color: #AEAEAE;
}

.farm-header-togglesDashboard {
  display: inline-block;
  width: 25%;
  padding: 12px 0;
  font-size: 12px;
  color: #50565F;
  text-transform: uppercase;
  border: 0.23999999463558197px solid #EDEDED;
  cursor: pointer;
  height: 40px;
}

.tab-container a {
  flex-grow: 1;
  width: 100%;
}

.trucks-tab-container {
  width: 100%;
}

.cash-flow-back-btn {
  border: 0.47999998927116394px solid #EDEDED !important;
}

.css-3u52cg-TopBar, .css-1uynwhj-TopBar {
  color: #22C687 !important;
}

.react-timekeeper__clock-hand, .css-wj4ovw-Meridiems {
  stroke: #6bff9c !important;
}

.css-wj4ovw-Meridiems, .css-8xneil-Meridiems {
  background-color: #7bffa7a3 !important;
}

.plot-tog-active .plots-header-toggles {
  border-bottom: solid 4px #22C687;
}

.plot-tog-active .plots-header-toggles-auto {
  border-bottom: solid 4px #22C687;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
  background-color: #02C170 !important;
}

.plot-tog-active .farm-header-toggles {
  border-bottom: solid 4px #22C687;
}

.plot-tog-active .farm-header-togglesDashboard {
  border-bottom: solid 4px #22C687;
}

.cash-flow .farm-header-toggles {
  width: 14.10%;
}

.xindicate .farm-header-toggles {
  width: 14.285%;
}

.system-setting .farm-header-toggles a{
  flex-grow: 1;
  width: 100%;
}

.dashboard .farm-header-togglesDashboard {
  width: 25%;
}

.daily-update .farm-header-toggles {
  width: 33.3333333333333%;
}

.settings .farm-header-toggles {
  width: 20%;
}

.settings-sa .farm-header-toggles {
  width: 16.66%;
}

.setting-500 .farm-header-toggles {
  width: 25%;
}

.settings-plot .plots-header-toggles {
  width: 33.33%;
}

.icon-in-tab {
  width: 12px !important;
  height: 12px !important;
  margin-top: -2px;
  margin-right: 4px;
}

.icon-in-tab-profile {
  width: 36px;
  height: 36px;
  border-radius: 29px;
}

.icon-participant-profile {
  width: 103px;
  height: 103px;
  margin-top: -100px;
  border-radius: 50px;
  position: absolute;
  margin-left: -48px;
  border: 4px solid #ffffff;
  z-index: 2;
}

.xindicate-dashboard-icon-in-tab-profile {
  width: 36px;
  height: 36px;
  margin-top: -12px;
  border-radius: 29px;
}

.icon-in-tabCSV {
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-left: 10px;
}

.toggle-table {
  display: inline-flex;
  padding-left: 3px;
  margin: 0px;
  color: #3F4451;
}


.cash-flow-repot-img-size {
  /* height: 7rem; */
  width: 6rem;
  padding-left: 22px;
  padding-right: 22px;
  /* background-color: aquamarine; */
  border: 0.1rem solid #02C170;
  border-radius: 50%;
  height: 6rem;

}

.cash-flow-repot-img {
  filter: grayscale(100%);
  cursor: pointer;
  background-color: #fff;
  margin-right: 1rem;
}

.react-datepicker-popper {
  transform: none !important;
  inset: auto !important;
}

.cash-flow-repot-img:hover {
  filter: grayscale(0%);
  color: #02C170;
  cursor: pointer;
}

.cash-flow-repot-img-selected {
  filter: grayscale(0%);
  color: #02C170;
  cursor: pointer;
  background-color: #fff;
  margin-right: 1rem;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #22c687 !important;
}

.rs-picker-toolbar-right-btn-ok {
  background-color: #22c687 !important;
}

.rs-picker-toolbar-right-btn-ok:hover {
  background-color: #02C170 !important;
}

.rs-calendar-table-cell-in-range:before {
  background-color: #c5ffc2 !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  background-color: #22C687 !important;
}


@media screen and (min-width: 992px) {
  .web-hide {
    display: none;
  }
}

.m-rl-m-8 {
  margin-right: -8px;
  margin-left: -8px;
}

#m-rl-m-16 {
  margin-right: -16px !important;
  margin-left: -16px !important;
}

#m-rl-m-16-2 {
  margin-right: -16px !important;
  margin-left: -16px !important;
}

#m-rl-m-16-3 {
  margin-right: -16px !important;
  margin-left: -16px !important;
}


#m-rl-m-16-4 {
  margin-right: -16px !important;
  margin-left: -16px !important;
}


#plotListContainer {
  margin-right: -16px !important;
  margin-left: -16px !important;
}


#sensorListContainer {
  margin-right: -16px !important;
  margin-left: -16px !important;
}

.sensor-details {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.25px solid #EDEDED;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
}

.sensor-details.warning {
  animation-name: status-blink;
  /*-webkit-animation-duration: 1.5s;*/
  animation-duration: 1.5s;
  /*-webkit-animation-iteration-count: infinite;*/
  animation-iteration-count: infinite;
}

@keyframes status-blink {
  from {
    border: 0.5px solid #ee0a0a;
    background: #d55151 0% 0% no-repeat padding-box;
  }
  to {
    border: 0.25px solid #EDEDED;
    background: #FFFFFF 0% 0% no-repeat padding-box;
  }
}

.summary-filter-box {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

@media (max-width: 435px) {
  .box-filter-date-selected {
    margin-top: 24px;
  }
}

/*.mb-10{*/
/*    margin-bottom: 10px !important;*/
/*}*/
/*.mb-16{*/
/*    margin-bottom: 16px !important;*/
/*}*/

.pull-right {
  margin-bottom: 16px !important;
}

.mb_-68 {
  margin-bottom: -68px;
}

.partcipant-details {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.25px solid #EDEDED;
  border-radius: 8px;
  width: 184px;
  padding: 8px;
  margin-bottom: 8px;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  height: 80px;
}

.bg-ededed-grey {
  background-color: #E7E8EA71 !important;
}

.px-web-32 {
  padding: 0 32px !important;
}

//.mt-2{
//  margin-top: 2px !important;
//}

.sensor-details-xin {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.25px solid #EDEDED;
  border-radius: 8px;
  width: 228px;
  padding: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  height: 64px;
}

.sensor-icon {
  display: inline-block;
  font-size: 26px;
  width: 42px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  background-color: #0000000A;
  border-radius: 50%;
}

.sensor-icon-size-40 {
  height: 40px !important;
  width: 40px !important;
}

.member-row {
  display: flex;
  flex-wrap: wrap;
}

.sensor-icon-profile {
  display: inline-block;
  font-size: 26px;
  width: 42px;
  height: 42px;
  text-align: center;
}

.sensor-iconPhone {
  display: inline-block;
  font-size: 26px;
  width: 42px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  margin-right: 26px;
  margin-top: 38px;
}

.sensor-iconParticipant {
  display: inline-block;
  font-size: 26px;
  width: 42px;
  height: 48px;
  text-align: center;
  vertical-align: super;
  margin-right: 50px;
}

.map-target {
  left: 406px;
  top: 262px;
  position: absolute;
}

.senz-icon-notification {
  display: inline-block;
  width: 42px;
  height: 42px;
  text-align: right;
  vertical-align: baseline;
  float: left;
  margin-top: 8px;
}

.range-map {
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 8px;
  position: absolute;
  right: 15px;
  top: 125px;
  text-align: left;
}

.map-data-indicater {
  right: 10px;
  top: 112px;
}

.sensor-icon .fa-battery-0 {
  font-size: 21px;
}

.sensor-data {
  padding-left: 3px;
  display: inline-block;
  vertical-align: middle;
  max-width: 128px;
}

.sensor-data-profile {
  display: inline-block;
  vertical-align: middle;
}

.participant-data {
  display: inline-block;
  margin-right: 86px;
  width: 153px;
  margin-left: 4px;
}

.notification-data {
  overflow: auto;
}

.dashboard-container {
  display: flex;
  width: 100%;
}

.farm-dashboard .full-list-view {
  padding-top: 60px;
  background-color: #f8f8f8;
}

.dashboard-layout-container {
  padding: 0rem 0 3rem 0.5rem;
}

.dashboard-half-container, .dashboard-2-half-container {
  width: 50%;
}

.dashboard-2-half-container {
  position: relative;
  right: -7px;
}

.dashboard-yield-card {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.red-button {
  background-color: #FF4343;
  color: #FFFFFF !important;
  margin-left: -16px !important;
  padding-left: 22px !important;
}

.sensor-value {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0;
  color: #22242A;
}

.sensor-Name {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #22242A;
  opacity: 0.4;
}

.sensor-data-count {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0;
  color: #50565F;
  text-transform: uppercase;
  opacity: 0.48;
}


.active-dropdown-menu {
  background: #fff !important;
}

.active-dropdown-menu:active {
  background: #99e4af34 !important;
  color: #000 !important;
}

.active-dropdown-menu:hover {
  background: #F8F8F8 !important;
}


.farm-img-edit {
  position: relative;
  z-index: 998;
  box-shadow: 0 5px 15px 0 rgb(32 33 36 / 35%);
  cursor: pointer;
  right: -70px;
  top: -24px;
  background: #fff;
  width: 37px;
  height: 37px !important;
  padding: 6px 9px;
  border-radius: 25px;
}

.warning-input-msg-groupMembers {
  color: #FF4343 !important;
  font-size: 10px;
  margin-left: 15px;
  margin-top: 15px;
}

.warning-input-msg-schedule {
  color: #FF4343 !important;
  font-size: 10px;
  margin-left: 260px;
  margin-top: 15px;


}


.warning-input-msg-days {
  color: #FF4343 !important;
  font-size: 10px;
  margin-left: 240px;
  margin-top: 5px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.warning-input-msg-days-start {
  color: #FF4343 !important;
  font-size: 10px;
  margin-left: 382px;
  margin-top: 5px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.warning-input-msg-days-stop {
  color: #FF4343 !important;
  font-size: 10px;
  margin-left: 397px;
  margin-top: 5px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.warning-input-msg-start {
  color: #FF4343 !important;
  font-size: 10px;
  margin-left: 40px;
  margin-top: 5px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.warning-input-msg-stop {
  color: #FF4343 !important;
  font-size: 10px;
  margin-left: 60px;
  margin-top: 5px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.warning-input-msg-container {
  display: flex;
}

@media (max-width: 1200px) and (min-width: 992px) {
  .warning-input-msg-days {
    color: #FF4343 !important;
    font-size: 10px;
    margin-left: 280px;
    margin-top: 5px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .warning-input-msg-days-start {
    color: #FF4343 !important;
    font-size: 10px;
    margin-left: 272px;
    margin-top: 5px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .warning-input-msg-days-stop {
    color: #FF4343 !important;
    font-size: 10px;
    margin-left: 287px;
    margin-top: 5px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
}

@media (max-width: 992px) {
  .warning-input-msg-container {
    display: contents;
  }

  .warning-input-msg-start-t {
    color: #FF4343 !important;
    font-size: 10px;
    margin-top: 0px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;
    margin-left: -10px;
  }
  .warning-input-msg-stop-t {
    color: #FF4343 !important;
    font-size: 10px;
    margin-top: 0px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;
    margin-left: -10px;
  }

  .warning-input-msg-days {
    color: #FF4343 !important;
    font-size: 10px;
    margin-left: 0px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .warning-input-msg-days-start {
    color: #FF4343 !important;
    font-size: 10px;
    margin-left: 0px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .warning-input-msg-days-stop {
    color: #FF4343 !important;
    font-size: 10px;
    margin-left: 0px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
}

.table-status-active {
  text-align: center;
  height: 36px;
  width: 90px;
  border-radius: 18px;
  background-color: rgba(34, 198, 135, 0.1);
  font-size: 14px;
  padding-top: 8px;
  margin-top: 5px;
  text-transform: uppercase;
  color: #22C687;
}

.table-status-inactive {
  text-align: center;
  height: 36px;
  width: 90px;
  border-radius: 18px;
  background: #0000000A 0% 0% no-repeat padding-box;
  font-size: 14px;
  padding-top: 8px;
  margin-top: 5px;
  text-transform: uppercase;
  color: #FF4343;
}

.table-status-available {
  text-align: center;
  height: 36px;
  width: 180px;
  border-radius: 18px;
  background-color: rgba(34, 198, 135, 0.1);
  font-size: 14px;
  padding-top: 8px;
  margin-top: 5px;
  text-transform: uppercase;
  color: #22C687;
}

.table-status-unavailable {
  text-align: center;
  height: 36px;
  width: 180px;
  border-radius: 18px;
  background: #0000000A 0% 0% no-repeat padding-box;
  font-size: 14px;
  padding-top: 8px;
  margin-top: 5px;
  text-transform: uppercase;
  color: #FF4343;
}

.table-actions {
  display: inline-block;
  color: #aaa;
  cursor: pointer;
  margin-right: 5px;
  size: 20px;
}

.devices-table-actions {
  display: inline-block;
  color: #aaa;
  cursor: pointer;
  margin-right: 1px;
}

.table-action {
  color: #aaa;
}

.map-type-box {
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  right: 15px;
  z-index: 8;
  background-color: white;
  height: 75px;
  width: 75px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  width: 75px;
  padding: 3px;
  cursor: pointer;
}

.map-type-container {
  z-index: 99;
  height: auto;
  background-color: white;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  position: absolute;
  right: 100px;
  justify-content: center;
  align-items: center;
  width: auto;
  border-radius: 6px;
  display: none;
  padding: 10px 16px;
}

.map-type-container .row .col-auto {
  width: 100px !important;
}

.map-type-container:hover {
  display: flex !important;
}

.map-view-type {
  width: 60px;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #ededee;
  cursor: pointer;
}

.map-view-text {
  font-size: 13px;
  text-align: center;
  color: gray;
}

.map-type {
  height: 69px;
  width: 69px;
  border-radius: 6px;
}

.map-container:hover > .map-type-container {
  display: flex !important;
}

.table thead th {
  vertical-align: top !important;
}

.card-header-status {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.card-header-status .heading {
  font-size: 14px;
  color: #50565F;
}

.card-header-status .sub-text {
  font-size: 12px;
  color: #50565F;
  opacity: 0.48;
}

.status-circle-green {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #22C687;
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
}

.status-circle-black {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #000000;
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
}

.card-header-actions {
  font-size: 14px;
  color: #50565F;
  cursor: pointer;
}

.gm-style .gm-style-iw {
  font-weight: 10;
  font-size: 10px;
  overflow: hidden;
  padding: 0px;
  text-align: center;
  width: 100px;
  margin: 0;
  height: 20px;
}

.gm-style-iw {
  overflow: scroll;
  max-width: inherit;
  max-height: 10px;
}

.gm-iv-address {
  background-color: #222;
  opacity: .8;
  outline-color: #000;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  display: inline-block;
  padding: 6px 8px;
  margin-top: 194px;
  height: 56px;
  box-sizing: border-box;
}

.gm-iv-marker-icon {
  overflow: hidden;
  width: 13px;
  height: 21px;
  position: absolute;
  background-position: -17px -50px;
  margin-top: 182px;
}

.gm-iv-address-link {
  position: absolute;
  top: 48px;
  margin-top: 184px;
}

.gm-iv-container {
  width: 27px;
  border-right: 1px solid #666;
  background-color: #222;
  opacity: .8;
  margin-top: 194px;
}

.gm-ui-hover-effect {
  display: none !important;
}


.sa-modal-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  border-radius: 8px;
  opacity: 1;
  padding: 24px 19px;
  max-width: 674px;
  margin: 0px auto;
}

.modal-scroll {
  height: 500px;
  overflow: auto;
}

.sa-modal-box-participant {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  border-radius: 8px;
  opacity: 1;
  padding: 45px;
  max-width: 674px;
  margin: 0px auto;
  top: 76px;
  left: 166px;

  height: 500px;
}


.sa-modal-box-groupView {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  border-radius: 8px;
  opacity: 1;
  padding: 45px;
  max-width: 841px;
  margin: 0px auto;
  top: 76px;
  left: 166px;
  height: 500px;
}


.sa-view-modal-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  border-radius: 8px;
  opacity: 1;
  padding: 45px;
  max-width: 1000px;
  margin: 0px auto;
}

.date-picker-centered {
  width: fit-content;
  border-radius: 8px;
  opacity: 1;
  padding: 45px;
  max-width: 674px;
  margin: 0px auto;
}


.sa-modal-close {
  float: right;
  text-align: center;
  background: #b4bacc 0% 0% no-repeat padding-box;
  opacity: 0.2;
  color: #000;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
}

.sa-modal-footer {
  padding: 30px 0 40px;
}

.sa-modal-footer-btn {
  float: right;
}


.btn-sa-width {
  width: 120px;
}

.add-btn-moblie-veiw {
  padding-top: 10px !important;
}

@media (max-width: 425px) {
  .btn-sa-width {
    width: 170px !important
  }
  .sa-modal-box-auditlogEachView {
    max-width: 305px;
  }
  .sa-modal-box {
    max-width: 290px;
  }
  .btn-size {
    height: 44px;
    width: 44px;
  }

  .edit-btn-size {
    margin-left: -2px;
    margin-top: 1px;
  }

  .sa-modal-box-mobile {
    max-width: 405px !important;
  }

  .btn-moblie-veiw {
    height: 36px !important;
    width: 36px !important;
    padding-top: 5px !important;
    padding-left: 9px !important;
  }


  .add-btn-moblie-veiw {
    padding-top: 6px !important;
    padding-left: 9px !important;
  }

  .edit-btn-color {
    background-color: #FFFFFF;
  }
  .hide-425 {
    display: none !important;
  }
  .mobile-view-m {
    margin-top: -10px;
    margin-right: 8px !important;
  }
  .weather-box-temp {
    padding-left: 38px;
  }
}

.mobile-view-m {
  margin-right: 16px;
}

@media (max-width: 411px) {
  .btn-sa-width {
    width: 150px !important
  }
}

@media (max-width: 515px) {
  .m-b-p-0 {
    padding: 0 !important;
  }
  .overflow-scroll {
    overflow: scroll !important;
  }
  .m-b-p-0 {
    padding: 0px !important;
  }
}

@media (max-width: 375px) {
  .btn-sa-width {
    width: 145px !important
  }

  .sa-modal-box-mobile {
    max-width: 355px !important;
  }

  .icon {
    display: flex;
  }
}

@media (min-width: 767px) {
  .map-household {
    width: 680px !important;
  }
}

@media (max-width: 360px) {
  .btn-sa-width {
    width: 130px !important
  }
  .btn-size {
    margin-right: 8px;
  }
}

@media (max-width: 325px) {
  .btn-sa-width {
    width: 125px !important
  }
}

.w-140p {
  width: 140px;
}

.w-200p {
  width: 200px;
}

.w-235p {
  width: 235px;
}


.single-card-item-heading {
  font-size: 16px;
  letter-spacing: 0;
  color: #3F4451;
  opacity: 1;
}

.scheduling-mode-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  padding: 12px 16px;
  color: #868DAA;
  font-size: 14px;
  width: 228px;
  display: inline-block;
  margin-bottom: 16px;
}

.scheduling-mode-box .change {
  float: right;
  color: #22C687;
  font-size: 10px;
  margin-top: 2px;
}

.scheduling-mode-box .clickable-link {
  float: right;
  color: #22C687;
  /*font-weight: 600;*/
  font-size: 10px;
  margin-top: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.h-44 {
  height: 44px;
}

.schedule-day-mode {
  width: 260px;
  margin: 4px auto;
  color: #868DAA;
  font-size: 14px;
}

.schedule-day-mode-day {
  display: inline-block;
  width: 130px;
}

.schedule-day-mode-time {
  display: inline-block;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  font-size: 18px;
  color: #22242A;
  padding: 9px 16px;
  width: 130px;
  height: 44px;
}

.turn-off-text {
  color: #181D33;
  opacity: 0.28;
  font-size: 12px;
}

.m-b-7 {
  margin-bottom: 7px;
}

.w-90 {
  width: 90px;
}

table td {
  color: #4E5983;
  font-size: 14px;
  vertical-align: middle !important;
}


.start-end-time {
  width: 120px;
  display: inline-block;
  margin-right: 16px;
}

.btn-icon {
  cursor: pointer;
  margin: 2px;
}

.farm-label-box {
  background: #22C687 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  opacity: 1;
}

select.box-filter-all:focus {
  outline: none;
}


.hide-table {
  display: none;
}


.icon-setting {
  width: 18px;
  height: 18px;
  margin-top: -2px !important;
  margin-right: 8px !important;
}

.icon-in-btn {
  width: 12px !important;
  height: 12px !important;
  margin-top: -2px !important;
  margin-right: 2px;
}

.icon-in-btn-map {
  width: 16px !important;
  height: 16px !important;
}

.icon-in-close {
  width: 18px;
  height: 18px;
  margin-top: -2px;
}

.m-r-40 {
  margin-right: 40px;
}

.z-9 {
  z-index: 9;
}

.z-999 {
  z-index: 999 !important;
}

.m-t-26 {
  margin-top: 26px;
}

.m-r_-26 {
  margin-right: -26px;
}

.m-r_-19 {
  margin-right: -19px;
}

.m-r_-30 {
  margin-right: -30px;
}

.m-r_10 {
  margin-right: 10px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-r-25 {
  margin-right: 100px;
}

.p-t-10 {
  padding-top: 10px !important;
}

.weather-box-temp-time {
  padding-top: 10px;
  padding-left: 2.5rem;
}

.farm-info-heading {
  letter-spacing: 0;
  color: #3F4451;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0 -13px;
  background-color: #ffffff;
}

.farm-info-title {
  color: #3F4451;
  font-size: 16px;
  margin-top: 8px;
}

.farm-info-data {
  color: #868DAA;
  font-size: 14px;
  margin-bottom: 53px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: black;
  border: none !important;
}

.custom-checkbox-th {
  margin-top: 8px !important;
}

.table-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  margin: 24px 0px 0;
  padding: 40px;
  width: 100%;
  overflow-x: auto;
  max-height: 520px;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-visible {
  overflow: visible !important;
}

.table-container-activity {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  margin: 0px 0px 0;
  padding: 40px;
  width: 100%;
  overflow-x: auto;
  max-height: 520px;
}

.table-plot-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  margin: 64px 0px 0;
  padding: 40px;
  width: 100%;

}

.table-container-field {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  margin: 0px 0px 0;
  padding: 40px;
  width: 97%;
  overflow-x: auto;
  height: 333px;
  margin-left: 10px;
  height: 250px;
}

.activityAddField {
  margin-top: 14px;
}

.table-container-wo-m-t {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  padding: 40px;
  width: 100%;
  overflow-x: auto;
  max-height: 520px;
}

.threshold-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  padding: 20px 35px;
  margin-bottom: 16px;
}

.threshold-title {
  color: #3F4451;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 36px;
}

.input-range__track--active {
  background: #02C170 !important;
  border: 0.6399999856948853px solid #E7E8EA !important;
}

.input-range__slider {
  background: #02C170 !important;
  border: 1px solid #109F62 !important;
}

.input-range__label {
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px !important;
}

.opacity-30 {
  opacity: 0.3;
}

//.input-range__label--min, .input-range__label--max {
//  top: -20px !important;
//}
.input-range-label {
  position: inherit;
  font-size: 14px;
  font-weight: bold;
}

.input-range-label-low {
  right: 50%;
  top: 2px;
}

.input-range-label-high {
  left: 50%;
  top: 2px;
}

.input-range__track {
  height: 12px !important;
}

.input-range__track--background {
  background: #F7F7F7 0% 0% no-repeat padding-box !important;
  border: 0.6399999856948853px solid #E7E8EA !important;
}

.input-range__slider {
  width: 18px !important;
  height: 18px !important;
  margin-top: -15px !important;
}

.input-range__label--value {
  top: -35px !important;
  color: #02C170 !important;
}

@media (min-width: 768px) {
  .custom-switch-align {
    margin-right: 40px;
  }
  .filter-left {
    margin-left: -10px;
  }
  .search-right .search {
    display: none;
  }
}

.xindicate .pull-left .form-control {
  width: 200px !important;
  margin-left: 8px;
}

.xindicate .pull-left .form-control-search {
  width: 200px !important;
  margin-left: 8px;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  height: 44px !important;
}

.m-r-40 {
  margin-right: 40px;
}

.time-box {
  cursor: pointer;
}

.time-box-field {
  padding-right: 30px !important;
}

/** Table widths **/

.tw-48 {
  width: 48px !important;
}

.tw-190 {
  width: 190px !important;
}

.tw-160 {
  width: 160px !important;
}

.tw-actions {
  min-width: 160px !important;
}

.tw-150 {
  width: 150px !important;
}

.table-actions-box {
  min-width: 170px;
}

.table-user {
  width: 1172px !important;
}

.table-width {
  width: 1172px !important;
}

.table-width-2 {
  width: 1172px !important;
}

.table-width-sm {
  width: 1172px !important;
}

.table-width-sm-field {
  width: 491px !important;
  margin-left: -14px;
}

.table-width-sm-auditlog {
  width: 683px !important;
  margin-left: -14px;
}

.table-width-xs {
  width: 1172px !important;
}

/** Table widths **/

table tbody tr:nth-child(odd) {
  /*background-color: #f9f9f9 !important;*/
  background-color: #f9f9f9;
}


.view-am {
  display: inline-flex;

}

@media (max-width: 420px) {
  .toggle-table {
    display: none;
  }
}

@media (max-width: 465px) {
  .card-summary-box-dashBoardParticipant {
    margin-left: 0px !important;
    margin-top: 9px !important;
  }
  .card-summary-box-dashBoardHouseHold {
    margin-left: 0px !important;
  }
}

@media (max-width: 992px) {
  .card-summary-box-dashBoardMobile {
    margin-top: 16px !important;
  }
  .half-list-container-dashBoard {
    margin-left: 0px !important;
  }
}

.dashboard-widget {
  justify-content: space-evenly;
}

.mo-list-view {
  position: absolute;
  background-color: #F8F8F8;
  bottom: 0;
  overflow: auto;
  width: 100%;
}

@media (max-width: 620px) {
  .mo-list-view {
    display: flex;
    padding: 16px;
  }

  .mo-list-view .single-card {
    margin-right: 20px;
  }

  #sensorListContainer .mo-plot-list-card:last-child .single-card {
    margin-right: 8px
  }

  .mo-list-view .single-card-header {
    padding: 16px;
    border-bottom: solid 1px #eee;
    width: 320px;
  }

  .card-content-heading {
    font-size: 11px;
  }

  .card-content-status {
    font-size: 11px;
  }
  .sa-bread-crumb {
    margin-left: 0px !important;
    margin-top: 5px !important;
  }
  .map-toggle-btn {
    padding: 6px 0;
  }
  .map-toggle-bg {
    height: 80px;
  }
  .full-list-view {
    padding-top: 130px;
  }
  .table-container {
    padding: 8px;
    margin-top: 56px;
  }
}

.web {
  margin-left: 2px;
}

.mo-single-card {
  -webkit-box-shadow: 0px 0px 8px #0000000A;
  -moz-box-shadow: 0px 0px 8px #0000000A;
  box-shadow: 0px 0px 8px #0000000A;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 0.23999999463558197px solid #EDEDED;
  background-color: #fff;
  width: 340px;
  margin-right: 20px;
}

.mo-single-card-header {
  padding: 16px;
  border-bottom: solid 1px #eee;
  width: 340px;
}

.gm-style .gm-style-iw {
  height: 36px;
  border-radius: 18px;
  text-align: center;
  width: auto;
  padding-top: 9px;
  min-width: 150px;
}

.farm-label-box {
  background-color: #fff;
  border: none;
  padding-left: 18px;

}

@-moz-document url-prefix() {
  .farm-label-box {
    padding: 0px 18px !important;
  }

}

.plot-label {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  color: #50565F;
  opacity: 1;
}

.table-container-heading {
  margin-top: 32px;
  margin-bottom: -48px;
}

.navbar {
  padding-right: 0 !important;
  z-index: 999;
}

.mobile-schedule {
  display: inline-block;
}

@media (max-width: 1290px) {
  .mobile-schedule {
    /*display: block;*/
  }
  .web {
    margin-left: 4px;
  }
}

@media (max-width: 992px) {
  .mobile-schedule {
    /*display: block;*/
    text-align: center;
  }

  .start-end-time label {
    float: left;
  }

  .btn-sa-week {
    border-radius: 50% !important;
    width: 20px !important;
  }

  .btn-sa-week-selected {
    border-radius: 50% !important;
    width: 44px !important;
  }

  .pis-btn-h {
    height: auto;
  }

  .pis-icon-h {
    height: auto;
  }

  .pis-pull-right {
    float: none !important;
    text-align: center;
    margin-top: 16px;
  }
}

@media (max-width: 992px) {
  .mobile-schedule {
    display: block;
  }

  .btn-sa-week {
    margin-right: 6px;
  }

  .btn-sa-week-selected {
    margin-right: 6px;
  }

  .table-user {
    width: 992px !important;
  }

  .table-width {
    width: 992px !important;
  }

  .table-width-2 {
    width: 800px !important;
  }

  .table-width-sm {
    width: 700px !important;
  }

  .table-width-xs {
    width: 500px !important;
  }
  .device-count {
    justify-content: center;
  }
  .card-summary-box-dashBoardMobile {
    margin-left: 0px !important;
  }
  .card-summary-box-dashBoardParticipantAnalysis {
    margin-left: 0px !important;
    width: 100% !important;
  }
}

.tw-210 {
  width: 210px !important;
}

@media (max-width: 1268px) {
  .card-summary-box-participantAgeRange {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .dashboardMaleFemaleChart {
    margin-right: 0px !important;
    text-align: center !important;
  }
}


.toggle-dropDown {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #868DAA;
  height: 48px;
}

.p-a-16 {
  padding: 16px !important;
}

.w-105 {
  width: 105px;
}

#toggle-list, #toggle-map {
  border: none !important;
}

.bg-none {
  background: transparent !important;
}


.empty-results-auditLog {
  padding: 64px 0;
  text-align: center;
  color: #999;
  width: 100%;
  height: 14px;
  margin-bottom: 46px;
}

@media (max-width: 480px) {
  .empty-results-notification {
    width: 320px;
  }

  .view-am {
    display: none;
  }
}

@media (max-width: 375px) {
  .empty-results-notification {
    width: 290px;
  }
}

.summary-offline {
  padding: 50px 0;
  text-align: center;
  color: #999;
  width: 100%;
}

.summary-offline-sensor {
  padding: 44px 0;
  text-align: center;
  color: #999;
  width: 100%;
}

.h-100pe {
  height: 100%;
}

.farm-info-set {
  border-top: 1px solid #EDEDED;
  padding-top: 32px;
}

.farm-info-heading-set {
  background-color: #ffffff;
  display: inline-block;
  padding-right: 10px;
  margin-left: -10px;
  padding-left: 10px;
}

.btn-sa-icon {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #FF4343 !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.map-icon {
  height: 12px;
  width: 12px;
  margin-top: -1px;
}

.border-bottom {
  border-bottom: 2px solid #000;
}

.half-list-container {
  background-color: #F8F8F8;
}

.half-list-container-dashBoard {
  padding-top: 75px;
  margin-left: 17px
}

.half-list-container-dashBoardBottom {
  padding-top: 17px;
  margin-left: 17px
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #02C170 !important;
  background-color: #02C170 !important;
}

.m-t-32 {
  margin-top: 32px;
}

.max-w-320 {
  max-width: 320px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.h-40 {
  height: 40px;
}

.plot-th-H {
  height: 40px;
}

.threshold-edit-select {
  max-width: 320px !important;
  margin: 0 auto;
}

.p-tb-16-rl-48 {
  padding: 16px 48px !important;
}

.pis-add {
  width: 140px;
}

.pis-add-activityFields {
  width: 25px !important;
  height: 36px;
  margin-left: -60px
}

.pis-add-activityFields .icon-in-btn {
  margin-left: -8px;
  display: flex;
}

.btn-sa-secondary-memberExportCSV {
  background: #f44336 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  border: solid 1px #f44336 !important;
  opacity: 1;
  height: 26px;
  color: #ffffff !important;
  font-size: 9px !important;
}

.pis-pull-right {
  float: right;
}

.pis-icon-h {
  height: 16px;
}

.pis-btn-h {
  height: 44px;
}

.show-768 {
  display: none !important;
}

.show-425 {
  display: none !important;
}

.m-b-30-info {
  margin-bottom: 30px;
}

.w-100pe {
  width: 100% !important;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-38 {
  margin-top: 38px !important;
}


.m-l-8 {
  margin-left: 8px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-b-8 {
  margin-bottom: 8px;
}

.p-t-5 {
  padding-top: 5px;
}

.m-rl-a {
  margin-right: auto;
  margin-left: auto;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-18 {
  margin-left: 18px !important;
}

.min-height-85 {
  min-height: 85px;
}

@media (max-width: 528px) and (min-width: 400px) {
  .card-summary-box-participantAgeRange {
    width: 400px;
  }
  .card-summary-box-dashBoardlocation {
    width: 184px;
  }
}

@media (max-width: 1199px) {
  .card-summary-box-dashBoardlocation {
    margin-left: 0px !important;
  }
  .card-summary-box-participantAgeRange {
    margin-left: 0px !important;
    margin-top: 16px;
  }
  .participant-graph {
    justify-content: center;
    margin-top: 16px !important;
  }
  .card-summary-box-dashBoardlocation {
    margin-top: 16px;
  }
  .card-summary-box-dashBoardParticipantName {
    margin-top: 16px !important;
  }
}

@media (min-width: 430px) and (max-width: 1199px) {
  .card-summary-box-dashBoardMobile {
    width: 384px !important;
  }
  #circle {
    margin-left: 80px !important;
  }
  .labelSizeViewMoreDeviceCount {
    margin-left: 300px !important;
  }
}

@media (max-width: 1243px) and (min-width: 991px) {
  .card-summary-box-location-map {
    width: 100% !important;
  }
}

@media (max-width: 1243px) {
  .card-summary-box-dashBoardParticipantAnalysis {
    margin-top: 16px !important;
    margin-left: 0px !important;
    justify-content: center;
  }
  .participant-graph {
    justify-content: center !important;
  }
}

@media (max-width: 1315px) and (min-width: 1200px) {
  .hide-1315 {
    display: none;
  }
  .btn-sa-week-selected {
    width: 90px !important;
  }
  .btn-sa-week {
    width: 90px !important;
  }
}

.pull-right-popup {
  float: right;
}

.mt-19 {
  margin-top: 19px;
}

@media (max-width: 1315px) {
  .hide-1315 {
    display: none;
  }
  .btn-sa-week-selected {
    width: 60px;
  }
  .btn-sa-week {
    width: 60px;
  }
}

@media(max-width: 376px) {
  .weather-box-position {
    position: absolute !important;
    left: 239px !important;
    top: 49px !important;
    width: 116px !important;
    height: 54px !important;
  }
}

.weather-pop-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 425px) {
  .hide-425 {
    display: none !important;
  }
  .show-425 {
    display: block !important;
  }
}

.background-color {
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

@media (min-width: 426px) {
  .hide-mobile-weather {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .hide-768 {
    display: none !important;
  }
  .first-tog-size {
    height: 36px;
    padding-left: 6px !important;
  }
  .second-tog-size {
    height: 36px;
    padding-left: 6px !important;
  }
  .mb-mw-min-content {
    max-width: min-content !important;
  }
  .col-form-label {
    font-size: 13px !important;
  }
  .heading-box {
    height: auto;
    flex-direction: column;
  }
  .farm-alert-level {
    padding: 12px !important;
  }
  .sa-modal-box-programView {
    height: 300px;
  }
  .show-768 {
    display: block !important;
  }
  .pro-0 {
    right: 246px;
  }
  .pro-100 {
    right: 90px;
  }
  .dashboard-container {
    flex-direction: column;
  }
  .today-alert-txt {
    font-size: 12px;
  }
  .task-percentage {
    font-size: 18px;
    margin: 0;
  }
  .task-progress {
    flex: 0 0 66.666667% !important;
    max-width: 60.666667% !important;
  }
  .today-alert-container {
    padding: 0 10px;
  }
  .dashboard-half-container, .to-do-box, .today-alert-box, .dashboard-widget-card {
    width: 100%;
  }
  .dashboard-2-half-container {
    right: 0;
  }
  .dashboard-irrigation-container {
    padding: 15px;
    width: 100%;
  }
  .dashboard-widget-card {
    margin-top: 1.5rem;
  }
  .pis-add {
    width: 80px;
  }

  .mobile-schedule {
    display: block;
    text-align: center;
  }
  .web {
    margin-left: 0px;
  }

  .btn-sa-week {
    border-radius: 50% !important;
    width: 33px !important;
    height: 33px;
    padding-left: 9px !important;
    text-align: center !important;
  }

  .btn-sa-week-selected {
    border-radius: 50% !important;
    width: 33px !important;
    height: 33px;
    padding-left: 9px !important;
    text-align: center !important;
  }

  .start-end-time {
    width: 130px;
    margin-right: 12px;
  }

  .pis-btn-h {
    height: auto;
  }

  .pis-icon-h {
    height: auto;
  }

  .pis-pull-right {
    float: none;
    text-align: center;
  }

  .dashboard-location-detail {
    justify-content: center !important;
  }
  .card-summary-box-dashBoardlocation {
    margin-left: 0px !important;
    margin-top: 16px !important;
  }
  .card-summary-box-dashBoardParticipantName {
    width: 264px !important;
    margin-top: 16px !important;
    margin-left: 0px !important;
  }

  .participant-data {
    margin-right: 0px;
  }
  .labelSizeViewMoreDashboard {
    margin-left: 170px !important;
  }
  .first-tog {
    width: 36px;
    border-radius: 50% !important;
    overflow: hidden;
    box-shadow: 0px 2px 2px #00000014 !important;
    color: #868DAA !important;
  }

  .second-tog {
    width: 36px;
    border-radius: 50% !important;
    overflow: hidden;
    box-shadow: 0px 2px 2px #00000014 !important;
    color: #868DAA !important;
  }

  .sa-modal-box {
    padding: 16px 8px;
  }

  .weather-name {
    padding-top: 10px;
  }

  .weather-time {
    padding-top: 10px;
  }

  .sa-view-modal-box {
    padding: 16px 8px;
  }


  .farm-info-data {
    margin-bottom: 16px !important;
  }

  .sa-modal-actuator {
    display: block;
    padding-top: 16px;
    overflow: auto;
  }

  .sa-modal-actuator .sa-modal-bg-inner {
    display: block;
  }

  .plot-report-tabs {
    width: 50% !important;
  }

  .plot-report-container {
    padding: 16px !important;
  }

  .weather-box {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.25px solid #70707000;
    border-radius: 8px;
    float: right;
    display: inline-block;
    width: 84px !important;
    height: 64px !important;
    vertical-align: middle;
    text-align: left !important;
    cursor: pointer;
  }

  .weather-box-position {
    //position: absolute !important;
    left: 578px !important;
    top: 49px !important;
    width: 116px !important;
    height: 54px !important;
  }

  .weather-box-temp {
    font: normal normal 600 14px/30px Poppins;
    line-height: 0px !important;
    text-align: center;
  }

  .weather-box-temp-time {
    padding-top: 10px;
  }

  .weather-main {
    font-size: 16px;
  }
  .plot-report-container {
    padding: 16px !important;
  }
}

.pis-pull-right button {
  display: block;
  width: 100%;
}

.edit-btn {
  margin-top: -25.15rem;
}

@media (max-width: 755px) {
  .mb-500 {
    width: 500px !important;
  }
}

.threshold-table {
  height: 400px;
  overflow: auto;
}

.alert-table {
  height: 350px;
  overflow: auto;
  margin-bottom: 10px;
}

.table-toggle-heading-frt {
  display: inline-block;
  background-color: #FFFFFF;
  color: #3F4451;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding-right: 6px;
}


@media (max-width: 408px) {
  .card-header-text-2 .heading {
    width: 74px;
  }
}

@media (max-width: 770px) {
  .space-between {
    margin-top: 30px;
  }

  .panel-view-item-icon {
    max-width: fit-content !important;
  }
}

.step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: 1px solid #707070;
  text-align: center;
  font: normal normal normal 16px/30px Poppins;
  letter-spacing: 0px;
  color: #707070;
  background-color: #FFFFFF;
  z-index: 1;
}

.steps-txt {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #707070;
  text-transform: capitalize;
}

.active-step {
  border: 2px solid #4ED19F;
  font: normal normal 600 16px/30px Poppins;
  letter-spacing: 0px;
  color: #4ED19F;
}

.active-txt {
  color: #4ED19F;
  font: normal normal 600 16px/25px Poppins;
}

.upload-img-farmer {
  position: relative;
  background: #EDEDED 0% 0% no-repeat padding-box;
  width: auto;
  height: 193px;
  top: 0px;
  width: -webkit-fill-available;
  box-shadow: 0px 0px 8px #0000000a;
  border: 1px dashed #757575;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.gap-20 {
  gap: 20px
}
.gap-8 {
  gap: 8px
}

@media (max-width: 374px) {
  .scheduling-mode-box {
    width: 200px;
  }

  .p-12 {
    padding: 0px;
  }

  .start-end-time {
    width: 120px;
    margin-right: 8px;
  }

  .sensor-value {
    font-size: 13px;
  }
  .sensor-value {
    font-size: 13px;
  }
  .sensor-Name {
    font-size: 11px;
  }

  .card-header-text-2 {
    margin-left: 0px;
  }

  .card-header-text-2 .heading {
    font-size: 12px;
  }

  .card-header-status .heading {
    font-size: 12px;
  }

  .card-header-status .sub-text {
    font-size: 11px;
  }

  .plot-report-tabs {
    width: 50% !important;
    padding: 5px 20px !important;
  }
  .table-toggle-heading {
    max-width: 220px !important;
    overflow: hidden;
  }
  .table-toggle-heading-frt {
    font-size: 12px;
  }
  .limit-chara-fis {
    display: block;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .card-header-actions {
    font-size: 12px;
  }
  .w-tab-auto {
    width: auto !important;
  }
}

.show-992 {
  display: none;
}

@media (max-width: 992px) {
  .hide-992 {
    display: none !important;
  }
  .m-font-size {
    font-size: 14px !important;
  }
  .dashboard-map {
    justify-content: center;
  }
  .dashboard-graph {
    justify-content: center;
  }
  .participantChart {
    width: auto !important;
  }
  .participant-graph {
    display: flex;
    flex-wrap: nowrap;
  }
  .card-summary-box-location-map {
    height: 320px;
  }
  .swap-box {
    padding: 3px !important;
  }

  .show-992 {
    display: block !important;
  }

  .farm-info-data {
    margin-bottom: 24px;
  }

  .m-b-30-info {
    margin-bottom: 0px;
  }
  .dashboard-map {
    justify-content: center !important;
    display: flex;
    flex-wrap: nowrap;
  }
}

.farm-heading-mobile {
  text-align: center;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  border-bottom: 0.23999999463558197px solid #EDEDED;;
}

@media (min-width: 768px) and (max-width: 992px) {
  .plot-report-tabs {
    width: 25% !important;
  }
}

@media (min-width: 492px) and (max-width: 992px) {
  .card-summary-box-dashBoardParticipantName {
    margin-left: 24px !important;
  }
}

.pis-border {
  border: 0.75px solid #3F44511F !important;
}

.plot-report-container {
  padding: 16px;
}

.plot-report-tabs {
  display: inline-block;
  width: 20%;
  text-align: center;
  background: #E7E8EA71 0% 0% no-repeat padding-box;
  padding: 20px;
  border: solid 0.5px #fff;
  cursor: pointer;
}

.plot-report-tabs-title {
  color: #3F4451;
  color: #868DAA;
}

.irrigation-switch {
  color: #3F4451;
  color: #868DAA;
  text-align: end;
  padding: 9px;
  display: inline-block;
}

.trendline-switch {
  color: #3F4451;
  color: #868DAA;
  text-align: end;
  padding: 9px;
  display: inline-block;
}

.plot-report-tabs-value {
  color: #3F4451;
  font-weight: lighter;
}

.plot-report-filter-box {
  width: 100%;
  height: 76px;
}

.plot-report-filter-box-plot {
  width: 100%;
  height: 76px;
  margin-top: 16px;
}

.plot-report-filter-box-cashflow {
  width: 100%;
  height: 76px;
}

.plot-report-filter {
  max-width: 250px;
  float: left;
}

.plot-report-filter-right {
  max-width: 250px;
  float: right;
  margin-bottom: 10px;
}

.sa-table-report-filter-right {
  float: right;
  margin-bottom: 10px;
}

.half-list-view .mo-plot-list {
  width: 100%;
  overflow: auto;
}

.show-620 {
  display: none !important;
}

@media (max-width: 620px) {
  .hide-620 {
    display: none !important;
  }
  .show-620 {
    display: block !important;
  }
  .half-list-view .mo-plot-list {
    display: flex;
    padding: 0 8px;
  }
  .half-list-view .mo-plot-list .single-card {
    margin-right: 20px;
  }

  .half-list-view .mo-plot-list .single-card-header {
    padding: 16px;
    border-bottom: solid 1px #eee;
    width: 340px;
  }

  #m-rl-m-16 {
    margin: 0 8px !important;
  }

  #m-rl-m-16-4 {
    margin: 0 !important;
  }

  #plotListContainer {
    margin: 0 !important;
  }

  #sensorListContainer {
    margin: 0 !important;
  }

  .full-list-view #m-rl-m-16-4 {
    margin: 0 8px !important;
  }

  .full-list-view #plotListContainer {
    margin: 0 -8px !important;
  }

  .full-list-view #sensorListContainer {
    margin: 0 8px !important;
  }

  .plot-container-resp {
    padding: 0 !important;
  }
  .card-img-view {
    width: 70px;
    height: 70px;
  }
  .card-img {
    height: 70px;
  }
  .sensor-value {
    font-size: 13px;
  }
  .sensor-icon {
    width: 40px;
  }
  .ma-res-lis {
    padding: 130px 8px 0 !important;
  }
  .mo-list-view .ma-res-bot {
    margin-bottom: 0 !important;
  }
}

.br-25 {
  border-radius: 0.25rem !important;;
}

.add-img .sc-bdnxRM {
  //border: 0.75px solid #3F44511F !important;
  width: min-content;
  cursor: pointer;
}

.mh-md-100 {
  max-height: 100%;
}

.img-close {
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 100%;
  background: #ededed8f;
  height: 22px;
  width: 22px;
  display: flex;
  cursor: pointer;
  box-shadow: 0px 0px 8px #0000000a;
  align-items: center;
  justify-content: center
}

.sc-bdnxRM svg {
  filter: grayscale(1) !important;
}

.farm-settings-blur {
  position: fixed;
  display: table;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.farm-settings-blur-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 19px;
}

.whit-text {
  color: #fff !important;
}

.toggle-btn {
  z-index: 9;
  pointer-events: all;
  position: relative;
  display: flex;
}

.module-polygon {
  cursor: url('images/markerIcon.png'), default !important;
}

.cursor-location-btn {
  color: white;
  background-color: #3a800d;
  position: relative;
  float: right;
  font-size: 18px;
  padding: 2px;
  margin: 3px 0;
  border-radius: 4px;
  cursor: pointer;
}

.location-picker .gm-style:first-of-type > div:nth-child(1) {
  cursor: url('images/marker.svg') 15 30, none !important;
}

.no-cursor {
  cursor: default !important;
}

input[type="text"]#subdomain {
  -webkit-appearance: none !important;

  display: inline-block;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-right: 0;
  border-radius: 4px 0 0 4px;
  font-size: 18px;
  color: #22242A;
  padding: 9px 0px 9px 16px;
  width: 73px;
  height: 44px;
}

input[type="text"]#subdomaintwo {
  -webkit-appearance: none !important;
  text-align: right;
  display: inline-block;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  font-size: 18px;
  color: #22242A;
  padding: 9px 5px 9px 0;
  width: 57px;
  height: 44px;
}

.empty-scroll-list {
  height: 32px;
  margin-bottom: 8px;
  position: fixed;
  width: 100%;
  z-index: 9;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5));
  /*background-color: #fff;*/
}

.empty-scroll-list-white {
  height: 8px;
  position: fixed;
  width: 100%;
  z-index: 9;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5));
  /*background-color: #fff;*/
}


.mo-list-view .single-card-header {
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.fade:not(.show) {
  opacity: unset !important;
}

.fade {
  pointer-events: auto !important;
}

.table-container-toggle {
  margin-top: 32px;
  margin-bottom: -48px;
  position: relative;
}

.table-toggle-hide {
  margin-bottom: 0px;
}

.table-toggle-line {
  margin-bottom: -12px;
  margin-right: 28px;
  top: -4px;
  width: 96%;
}

.table-toggle-heading {
  display: inline-block;
  background-color: #FFFFFF;
  color: #3F4451;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding-right: 6px;
}

.table-dropdown-icon {
  display: inline-block !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px #ddd;
  background-color: #eee;
  text-align: center;
  font-size: 13px;
  margin-left: 8px;
  float: right;
  cursor: pointer;
  margin-bottom: 10px !important;
}

.sa-bread-crumb {
  display: inline-block;
  z-index: 9;
  position: relative;
  color: #868DAA;
  font-size: 12px;
  border: 0.4px solid #EDEDED;
  border-radius: 4px;
  margin-left: -8px;
  pointer-events: all;
  box-shadow: 0px 0px 8px #0000000A;
}

.sa-bc-home {
  display: inline-block;
  background: #fff;
  height: 36px;
  padding: 9px 12px;
  border-radius: 4px 0 0 4px;
}

.sa-bc-farm {
  display: inline-block;
  background: #fff;
  height: 36px;
  padding: 9px 9px 9px 0;
}

.sa-bc-plot {
  display: inline-block;
  background: #fff;
  height: 36px;
  padding: 9px 9px 9px 0;
  border-radius: 0 4px 4px 0;
}

.sa-bc-arrow {
  color: #ddd;
  margin-right: 4px;
  height: 19px;
}

.sa-bc-right-border {
  border-radius: 0 4px 4px 0;
}

.cursor-location-btn {
  padding: 4px 6px;
  font-size: 12px;
  background-color: #22C687;
  margin-top: 6px;
}

.res-week-filter {
  margin-top: 48px;
  margin-bottom: -48px;
}

.map-z-index {
  z-index: 1;
}

.map-household {
  z-index: 1;
}

.half-list-view .m-t-36 {
  margin-top: 36px;
}

.half-list-view .m-t-16 {
  margin-top: 16px;
}

.empty-top-map {
  margin-bottom: 8px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.mo-list-view .single-card:last-child {
  margin-right: 16px;
}

.mo-list-view-last {
  padding: 1px;
}

.rs-picker-toggle-clean {
  display: none !important;
}

.tree-comp-container {
  border: solid 1px #eaeaea;
  border-radius: 2px;
  overflow: hidden;
}

.tree-comp-title {
  margin-top: 32px;
}

.tree-comp-title-line {
  margin-bottom: -12px;
}

.tree-comp-title-head {
  display: inline-block;
  background-color: #FFFFFF;
  padding-right: 6px;
}

.ir-manual-ind {
  width: 288px;
  margin: 0 auto;
}

.ir-manual-ind-c {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 8px;
  background-color: #FFFFFF;
  padding-top: 10px;
}

.ir-manual-ind-c.success {
  border: solid 1px #02C170;
  color: #02C170;
}

.ir-manual-ind-c.error {
  border: solid 1px #FF4343;
  color: #FF4343;
}

.ir-manual-ind-c.left {
  float: left;
}

.ir-manual-ind-c.right {
  float: right;
}

.ir-manual-ind-line-text {
  display: block;
  font-size: 12px;
  margin-top: -28px;
  margin-bottom: 24px;
}

.ir-manual-ind-line {
  /*width: 250px;*/
  border-bottom: dotted 1px #555;
  margin-top: 30px;
  margin-bottom: 32px;
  font-size: 12px;
  padding-bottom: 4px;
  height: 40px;
}

.ir-manual-ind-line-tank {
  /*width: 250px;*/
  margin-top: 30px;
  margin-bottom: 32px;
  font-size: 12px;
  padding-bottom: 4px;
  height: 40px;
}

.ir-manual-icon {
  height: 40px !important;
  width: 40px !important;
  stroke-width: 1;
}

.ir-manual-icon-alert {
  height: 16px;
  width: 16px;
  stroke-width: 1;
  color: #555;
}

.ir-manual-title {
  font-size: 14px;
  margin-top: 14px;
  color: #555;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

.react-datepicker-wrapper {
  width: 100%;
}

.box-filter {
  margin-left: 10px;
  padding: 6px;
  background: #FFFFFF !important;
  border: 0.75px solid #3F44511F !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  font-size: 14px !important;
  color: #868DAA !important;
  cursor: pointer;
}

@media (max-width: 768px) {
  .displaySearch {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
}

.box-filter-all {
  margin-left: 10px;
  padding: 6px;
  background: #FFFFFF !important;
  border: 0.75px solid #3F44511F !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  font-size: 14px !important;
  color: #868DAA !important;
  cursor: pointer;
}

.box-filter-allPlots {
  margin-left: 10px;
  padding: 4px;
  background: #FFFFFF !important;
  border: 0.75px solid #3F44511F !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  font-size: 14px !important;
  color: #868DAA !important;
  cursor: pointer;
}

.box-exportCSV {
  width: 140px;
}

@media (max-width: 530px) {
  .box-filter-all {
    margin-left: 3px;
    width: 54px;
  }
  .box-filter-allPlots {
    margin-left: 3px;
    width: 88px;
  }
  .box-exportCSV {
    width: 110px;
  }
  .alert-log-filter {
    width: 110px !important;
  }
}

@media (max-width: 424px) {
  .box-filter-all {
    margin-left: 5px;
  }
  .box-filter-allPlots {
    margin-left: 5px;
  }
  .box-filter-allPlots {
    width: 92px;
  }
  .alert-log-filter {
    width: 100px !important;
  }
}

@media (max-width: 424px) {
  .alert-log-filter {
    width: 68px !important;
  }
}

.rs-picker-daterange-calendar-group {
  display: contents;
}

.scouting-card {
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  background-color: #FFFFFF;
  padding: 16px 12px 10px;
}

.scouting-card-image {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  background-color: #868DAA;
  display: inline-block;
}

.scouting-card-name {
  font-size: 16px;
  font-weight: 600;
  color: #181D33;
  display: inline-block;
  margin-left: 16px;
  width: 300px;
}

.scouting-card-date {
  font-size: 14px;
  display: inline-block;
  color: #3F4451;
  width: 150px;
}

.scouting-card-time {
  font-size: 14px;
  display: inline-block;
  color: #3F4451;
  width: 150px;
}

.scouting-card-assignee {
  font-size: 14px;
  display: inline-block;
  color: #868DAA;
  width: 230px;
}

.scouting-card-scouter {
  font-size: 14px;
  display: inline-block;
  color: #868DAA;
}

.assignee-profile {
  height: 24px;
  width: 24px;
  display: inline-block;
  border-radius: 50%;
  background-color: #868DAA;
  vertical-align: bottom;
}

.scouting-card-details {
  display: inline-block;
  vertical-align: top;
  margin-top: 34px;
}

.scouting-as-head {
  color: #3F4451;
}

.scouting-date-time {
  display: inline-block;
}

.scouting-card-details-top {
  display: inline-block;
}

.scouting-card-details-bottom {
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 1200px) {
  .scouting-date-time {
    display: block;
    margin-left: 16px;
  }
  .hide-1200 {
    display: none !important;
  }
  .scouting-card-date {
    width: 120px;
  }
  .scouting-card-time {
    width: 100px;
  }

}

@media (max-width: 992px) {
  .scouting-card-date {
    display: block;
    margin-top: 8px;
  }
  .scouting-card-time {
    display: block;
    margin-top: 8px;
  }
  .scouting-card-details {
    margin-top: 8px;
  }
  .card-summary-box-location-map {
    width: auto !important;
    height: 360px !important;
  }
  .scouting-card-res-bottom {
    margin-top: 4px;
  }
  .scouting-card-assignee {
    display: block;
    margin-bottom: 16px;
  }
  .scouting-card-scouter {
    display: block;
  }
}

@media (max-width: 768px) {
  .scouting-card-date {
    display: block;
    margin-top: 8px;
  }
  .displaySearch {
    display: none !important;
  }
  .hide-375 {
    display: none !important;
  }
  .scouting-card-time {
    display: block;
    margin-top: 8px;
  }
  .scouting-card-details {
    margin-top: 8px;
  }
  .scouting-card-res-bottom {
    margin-top: 4px;
  }
  .scouting-card-assignee {
    display: inline-block;
    margin-bottom: 0;
  }
  .scouting-card-scouter {
    display: inline-block;
  }
}

@media (max-width: 575px) {
  .scouting-card-assignee {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sa-modal-box-groupView {
    padding: 24px;
  }
  .scouting-card-scouter {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .scouting-card-name {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .scouting-date-time {
    margin-top: -8px;
  }
}

@media (max-width: 320px) {

  .mobile-view-m {
    margin-right: 0px !important;
  }

}

@media (max-width: 375px) {
  .scouting-card-image {
    width: 76px;
    height: 76px;
  }
  .scouting-card-date {
    margin-top: 4px;
  }
  .scouting-card-time {
    margin-top: 4px;
  }
  .scouting-card-details {
    margin-top: 4px;
  }
  .scouting-card-assignee {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .scouting-card-scouter {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .width-stock {
    width: 40px;
  }
}

@media (max-width: 320px) {
  .text-size-color {
    font-size: 10px !important;
  }

  .text-size-color-report {
    font-size: 10px !important;
    padding-left: 0px !important;
    margin-left: 10px !important;
  }

  .border-report-wastage {
    width: 12.15rem !important;
    margin-top: 10px !important;
    margin-left: -1px;
  }
}

@media(max-width: 375px) {
  .plot-report-filter-right {
    float: left !important;
    margin-top: 10px;
  }
}

@media(min-width: 380px) and (max-width: 425px) {
  .border {
    float: right;
  }
  .plot-report-filter-right {
    float: left !important;
    margin-top: 10px !important;
  }
  .border-report-wastage {
    margin-left: -60px;
  }
  .img-center-768 {
    margin: 0 auto;
    display: block;
  }
}

.icon-sa {
  margin-left: 10px;
  margin-top: 25px;
  height: 24px;
  width: 24px;
  display: block;
}

.icon-sa.icon-motor {
  background: url('images/icons/motor.png') no-repeat;
}

.icon-sa.icon-electricity {
  background: url('images/icons/electricity.png') no-repeat;
}

.icon-sa.icon-solenoid {
  background: url('images/icons/solenoid.png') no-repeat;
}

.icon-sa-moisture {
  height: 20px;
  width: 25px;
  display: inline-block;
  padding-bottom: 23px;
}

.sensor-icon-moisture {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.icon-soil-temp {
  height: 20px;
  width: 25px;
  display: inline-block;
  padding-bottom: 13px;
}

.icon-soil-conductivity {
  height: 20px;
  width: 25px;
  display: inline-block;
  padding-bottom: 54px;
}

.goog-te-gadget img {
  background: url("images/icons/translate.png") no-repeat !important;
  width: 24px;
  height: 24px;
  padding-right: 30px !important;
}

.goog-te-gadget-simple .goog-te-menu-value, .goog-te-gadget-simple .goog-te-menu-value span {
  display: none;
}

.goog-te-gadget-simple {
  border: none !important;
}

.icon-sa-moisture.icon-moisture {
  background: url('images/icons/moisture.svg') no-repeat;
}

.icon-sa-moisture.icon-soil-temp {
  height: 25px;
  background: url('images/icons/soil_temp.svg') no-repeat;
}

.icon-sa-moisture.icon-soil-conductivity {
  height: 25px;
  background: url('images/icons/soil-conductivity.svg') no-repeat;
}

.icon-sa-moisture.icon-evaporation {
  background: url('images/icons/evaporation.svg') no-repeat;
}

.icon-sa-moisture.icon-rain-fall {
  height: 25px;
  background: url('images/icons/rainfall.svg') no-repeat;
}

.icon-sa-moisture.icon-wind-direction {
  height: 25px;
  background: url('images/icons/wind_speed.svg') no-repeat;
}

.icon-sa-moisture.icon-wind-speed {
  height: 25px;
  background: url('images/icons/wind_direction.svg') no-repeat;
}

.icon-sa-moisture.icon-sun-intensity {
  background: url('images/icons/sun_intensity.svg') no-repeat;
}

.vm-modal-title {
  font-size: 19px;
  font-weight: 600;
}

.vm-modal-title2 {
  display: inline-block;
  margin-right: 4px;
}

.vm-modal-title3 {
  display: inline-block;
  margin-right: 4px;
  margin-left: 20px;
}

.container-vm {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: top;
  margin-left: 8px;
}

/* Hide the browser's default radio button */
.container-vm input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}


/* On mouse-over, add a grey background color */
.container-vm:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-vm input:checked ~ .checkmark {
  background-color: #02C170;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-vm input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-vm .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.farm-header-tab-box {
  display: inline-block;
  float: right;
  margin-top: 69px;
  margin-right: -16px;
}

.farm-header-tab {
  display: inline-block;
  padding: 10px 72px;
  border: solid 1px #dddddd;
  text-transform: uppercase;
  color: #50565F;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
}

.tab-active .farm-header-tab {
  background-color: #fff;
  border-bottom: solid 1px #fff;
}

.tab-active .green-text {
  color: #22C687;
}

.bg-light-grey {
  background-color: #f5f5f5;
}

.sc-back {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
}

.sc-back-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.scout-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 100%;

}

.popup-graph-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 9px 0px 0px;
  width: 100%;
  overflow-x: auto;
  align-items: center;
}

@media (max-width: 768px) {
  .scout-container {
    width: 83vw;
  }
  .table-container {
    margin-top: 12px;
  }
}

@media (min-width: 768px) {
  .col-md-8 {
    max-width: 100% !important;
  }
}

@media (max-width: 576px) {
  .popup-graph-container {
    width: 86vw;
  }
}

@media (max-width: 425px) {
  .popup-graph-container {
    width: 83vw;
  }
  .report-icon-p {
    margin-left: -6px;
    margin-top: -6px;
  }
  .float {
    float: right;
  }
  .sensor-data-p {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .weather-box-position {
    //position: absolute !important;
    left: 284px !important;
    top: 49px !important;
  }
}

@media (max-width: 375px) {
  .weather-box-position {
    position: absolute !important;
    left: 237px !important;
    top: 49px !important;
  }
}


@media (max-width: 375px) {
  .popup-graph-container {
    width: 79vw;
  }
}


.scout-head {
  font-size: 14px;
  margin-top: 32px;
  margin-left: 8px;
  color: #555555;
  width: 90px;
}

.scout-value {
  font-size: 16px;
  color: #000000;
  background-color: #FAFAFA;
  border-radius: 4px;
  padding: 9px;
  width: 300px;
  overflow-wrap: break-word;
}

.scout-image-container {
  background-color: #FAFAFA;
  border-radius: 4px;
  padding: 9px;
  margin: 5px 5px 10px 6px;
  max-width: 300px;
  max-height: 200px;
  overflow: auto;
  /* overflow-wrap: break-word; */

}

.scout-image {
  display: inline-block;
  width: 140px;
  height: 140px;
  background-color: #868DAA;
  margin: 8px 16px 0 0;
  border-radius: 4px;
}

//.input-range__label--min .input-range__label-container {
//  margin-right: 40px !important;
//  top:50%;
//}
//.input-range__label-container {
//  position: absolute !important;
//}
//.input-range__label--max .input-range__label-container {
//  margin-left: 40px !important;
//  top:50%;
//}

.list-group-item:hover {
  background: #007bff !important;
  color: white;
  font-weight: bold;
}

.overflow-y-auto {
  overflow-y: auto;
}


.card-summary-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.25px solid #EDEDED;
  border-radius: 8px;
  opacity: 1;
  padding: 16px;
}

.card-summary-box-dashBoard {
  top: 230px;
  left: 122px;
  width: 196px;
  height: 148px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 5px;
  opacity: 1;
  padding: 16px;

}

.card-summary-box-dashBoard-mobileview {
  width: 128px;
  height: 96px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 5px;
  opacity: 1;
  padding: 12px;
  margin-top: 8px;
  justify-content: space-between !important;
}

.card-summary-box-dashBoardMobile {
  top: 230px;
  left: 122px;
  width: 249px;
  height: 304px;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  opacity: 1;
  padding: 16px;
  margin-left: 27px;

  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 6px;

}

.card-summary-box-dashBoardlocation {
  top: 230px;
  left: 122px;
  width: 184px;
  height: 304px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 16px;
  margin-left: 235px;
  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 6px;
}

.card-summary-box-dashBoardParticipantName {
  top: 230px;
  left: 122px;
  width: 337px;
  height: 304px;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  opacity: 1;
  padding: 16px;
  margin-left: 43px;

  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 6px;

}

.card-summary-box-dashBoardParticipantAnalysis {
  top: 230px;
  left: 122px;
  width: 547px;
  height: 350px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 16px;
  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 6px;
  margin-left: 640px;
  margin-top: -351px;

}

.card-summary-box-participantAgeRange {
  top: 230px;
  left: 122px;
  width: 488px;
  height: 304px;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  opacity: 1;
  padding: 16px;
  margin-left: -109px;

  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 6px;

}

.card-summary-box-location {
  top: 230px;
  left: 122px;
  width: 625px;
  height: 608px;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  opacity: 1;
  padding: 16px;
  margin-left: -14px;

  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 6px;

}

.card-summary-box-location-map {
  top: 230px;
  left: 122px;
  width: 625px;
  height: 685px;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  opacity: 1;
  padding: 16px;
  margin-left: -14px;

  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 6px;
}

.card-summary-box-locationMaximum {
  top: 230px;
  left: 122px;
  width: 623px;
  height: 48px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 12px;
  margin-left: -13px;
  margin-top: 26px;
  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 6px;

}

.card-summary-box-dashBoardHouseHold {
  top: 230px;
  left: 122px;
  width: 196px;
  height: 148px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 5px;
  opacity: 1;
  margin-left: 10px;
  margin-top: 9px;
  padding: 16px;
}

.card-summary-box-dashBoardParticipant {
  top: 230px;
  left: 122px;
  width: 196px;
  height: 148px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 5px;
  opacity: 1;
  margin-left: 10px;
  padding: 16px;
}

.card-summary-box-dashBoardGroup {
  top: 230px;
  left: 122px;
  width: 196px;
  height: 148px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #6d49491a;
  border-radius: 5px;
  opacity: 1;
  margin-top: 9px;
  padding: 16px;
}

.card-summary-img-border {
  width: 24px;
  height: 24px;
  border: 1px solid;
  border-radius: 12px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  // align-items: center;
  padding: 3px;
}

.border-red {
  border-color: red;
}

.card-summary-value {
  font-size: 13px;
  font: Light Poppins;
  letter-spacing: 0px;
  color: #22242A;
  opacity: 0.4;
}

.card-summary-heading {
  font-size: 14px;
  font: Medium Poppins;
  letter-spacing: 0px;
  color: #22242A;
  opacity: 1;
}


@media (max-width: 375px) {
  .card-summary-heading {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .card-summary-heading {
    font-size: 12px;
  }
}

.card-summary-img-no-boarder {
  width: 24px;
  height: 24px;
}

.card-summary-img-no-boarder-cashflow {
  width: 12px;
  height: 12px;
}

.card-summary-img-no-boarderDashBoard {
  width: 90px;
  height: 90px;
}

.card-summary-img-no-boarderPhone {
  width: 60px;
  height: 60px;
  margin-left: 4px;
}

.card-summary-img {
  border: 1px solid;
  border-radius: 12px;
  width: 24px;
  height: 24px;
}


.more-action-btn {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  display: inline-block;
}

.more-actions-icon {
  color: #000000;
  width: 20px;
  height: 20px;
  color: #000000;
  opacity: 0.6;
  cursor: pointer !important;
}

.more-actions {
  margin-left: 20px;
  text-align: center;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0.28px;
  color: #000000;
  opacity: 0.6;
  margin-top: 10px;
  cursor: pointer !important;
}

.dropdown-item:active {
  background-color: #e9ecef !important;
}

.border-blue {
  border-color: #1592E6;
}

@media print {
  .xindicate .xindicate-full-list-view {
    padding: 0 !important;
  }
  .pdf-hide {
    display: none !important;
  }
  .pageBreak {
    break-after: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }
  .sa-table-container, .table-container {
    max-height: max-content !important;
    overflow: visible !important;
  }
}

.water-resource .farm-header-toggles {
  flex-grow: 1;
  width: 100%;
}

.buyer-details .farm-header-toggles {
  flex-grow: 1;
  width: 100%;
}

.disabled-action {
  pointer-events: none !important;
  color: #d8d8d8;
}

.border-green {
  border-color: #22C687;
}

.border-orange {
  border-color: #FF9800;
}

.limit-chara-actuator-sensor {
  display: block;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.limit-charater-DashboardCard {
  display: block;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.participantChart {
  margin-top: 2px;
}

.border {
  border: 1px solid #000;
}

.border-report {
  border: 1px solid #dee2e6 !important;
}

.border-report-wastage {
  border: 1px solid #dee2e6 !important;
}

.float-l {
  float: left;
}

.text-size-color {
  color: #02C170;
  font: normal normal 600 14px/21px Poppins;
}

.text-size-color-report {
  color: #02C170;
  font: normal normal 600 14px/21px Poppins;
}

.report-heading {
  font: normal normal bold 14px/25px Poppins;
}

.limit-chara-member {
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.limit-chara-groupMemberName {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 108px;
}

.limit-charater-xindicateViewMemberCard {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
  margin-top: -4px;
}

.limit-charater-xindicateViewLabel {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 205px;
  margin-left: 0px;
}

.limit-charater-xindicateTableText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 240px;
}

.btn-light-1 {
  background-color: #007bff;
}

.limit-chara-fs {
  display: block;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 18px;
}

.limit-sensorName-sa {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  //width: 120px;
}

@media (max-width: 768px) {
  .limit-chara-fs {
    width: 160px;
    margin-left: 70px;
  }
}

@media (max-width: 425px) {
  .limit-chara-fs {
    width: 120px;
    margin-left: 15px;

  }
}

@media (max-width: 375px) {
  .limit-chara-fs {
    width: 100px;
    margin-left: 10px;

  }
}

@media (max-width: 320px) {
  .limit-chara-fs {
    width: 100px;
    margin-left: 5px;

  }
}


@media (max-width: 425px) {
  .limit-sensorName {
    display: block;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 20px;
  }

  .limit-sensorName-sa {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80px;
  }

  .limit-sensorValue {
    display: block;
    width: 100px;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .limit-sensorValue-sa {
    display: block;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (max-width: 425px) {
  .limit-des {
    display: block;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 20px;
  }
}

@media (max-width: 375px) {
  .limit-des {
    display: block;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 20px;
  }
}

@media (max-width: 320px) {
  .limit-des {
    display: block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 20px;
  }

  .limit-sensorName-sa {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 69px;
  }

  .limit-sensorValue-sa {
    display: block;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 69px;
  }
}

@media (max-width: 320px) {
  .limit-sensorValue {
    display: block;
    width: 60px;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 10px;
    text-overflow: ellipsis;
  }

  .limit-sensorName {
    display: block;
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media all and (max-width: 992px) {
  .plot-title-size {
    font-size: 14px !important;
  }
  .card-summary-box-dashBoardParticipantAnalysis {
    margin-left: 0px !important;
    margin-top: 16px !important;
  }

}

@media (max-width: 425px) {
  .limit-sensorName {
    display: block;
    width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media all and (max-width: 991px) {
  .plot-title-size {
    font-size: 14px !important;
  }

}


@media (max-width: 768px) {
  .limit-plot-report-N {
    display: block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: -15px;
  }
}

@media (max-width: 425px) {
  .limit-plot-report-N {
    display: block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 0px;
  }
}

@media (max-width: 375px) {
  .limit-plot-report-N {
    display: block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: -15px;
  }
}

@media (max-width: 320px) {
  .limit-plot-report-V {
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    margin-left: -2px;

  }

  .limit-plot-report-N {
    display: block;
    width: 150px;
    overflow: hidden;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: -30px;

  }
}

.padding-3 {
  padding: 3px;
}

.limit-chara {
  display: block;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.max-w-768 {
  max-width: 768px;
}

.rs-picker-default .rs-picker-toggle {
  z-index: 0 !important;
}

.rs-picker-toggle-value {
  color: #757575 !important;
}

.rs-picker-menu {
  z-index: 999 !important;

}

.config-container {
  height: 100%;
  background: #FFFFFF 0% 0%;
  opacity: 0.77;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .feature {
    padding-left: 29px;
    font: Bold 20px/35px Poppins;
  }
  .custom-switch-p {
    margin-left: -19px !important;
  }
  .config-setting-content {
    font-size: 10px !important;
    margin-left: 10px;
  }
  .feature-module {
    padding-left: 38px !important;
  }

  .config-setting {
    padding-left: 10px !important;
  }
}

.feature {
  padding-left: 45px;
  padding-top: 16px;
  text-align: left;
  font: Bold 20px/35px Poppins;
  letter-spacing: 0.48px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  padding-bottom: 16px;
}

.feature-module {
  padding-left: 22px;
  padding-top: 33px;
  text-align: left;
  font: Bold 24px/35px Poppins;
  letter-spacing: 0.48px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

@media (max-width: 425px) {
  .feature {
    padding-left: 30px;
    font: Bold 20px/35px Poppins;
    padding-top: 8px;
    padding-bottom: 8px !important;
  }
}

.config-setting {
  text-align: left;
  font-size: 16px;
  font-family: SemiBold, Poppins;
  padding-left: 0px;
  padding-top: 0px;
  letter-spacing: 0.32px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 2px;
}

@media (max-width: 425px) {
  .config-setting {
    margin-left: 20px;
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .config-setting {
    margin-left: 21px;
  }
}

.config-setting-content {
  text-align: left;
  line-height: 20px;
  padding-left: 0px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 0.28px;
  color: #646262;
  text-transform: capitalize;
  margin-top: 0;

}

.module-config-icon {
  height: 44px;
  width: 44px;
  border-radius: 50px;
  font-size: 14px;
  padding-top: 5px;
  vertical-align: center;
  margin-top: 10px;
  margin-left: 20px;
  text-transform: uppercase;
  background: #DFE0FD 0% 0% no-repeat padding-box;
}

.cash-flow-icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1 !important;
  text-align: center;
  margin: 0 auto;
  color: #FF9800 !important;
}

.module-config-icon1 {
  height: 44px;
  width: 44px;
  border-radius: 50px;
  font-size: 14px;
  padding-top: 5px;
  vertical-align: center;
  margin-top: 10px;
  margin-left: 20px;
  /* color: #FDEACF !important; */
  text-transform: uppercase;
  background: #FDEACF 0% 0% no-repeat padding-box;
}

.smart-farm-icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  color: #589EFF;
}

.sensor-data-icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  color: #589EFF;
}

.smart-farm-weather-icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  color: #FF4343;
}

.module-config-icon2 {
  height: 44px;
  width: 44px;
  border-radius: 50px;
  font-size: 14px;
  padding-top: 5px;
  vertical-align: center;
  margin-top: 10px;
  margin-left: 20px;
  text-transform: uppercase;
  background: #e6ebf3 0% 0% no-repeat padding-box;
}

.module-config-weather-icon {
  height: 44px;
  width: 44px;
  border-radius: 50px;
  font-size: 14px;
  padding-top: 5px;
  vertical-align: center;
  margin-top: 10px;
  margin-left: 20px;
  text-transform: uppercase;
  background: #E8E9EF 0% 0% no-repeat padding-box;
}

.daily-update-icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1 !important;
  text-align: center;
  margin: 0 auto;
  color: #22C687;
}

.module-config-icon3 {
  height: 44px;
  width: 44px;
  border-radius: 50px;
  font-size: 14px;
  padding-top: 5px;
  vertical-align: center;
  margin-top: 10px;
  margin-left: 20px;
  text-transform: uppercase;
  background: #D5F3E7 0% 0% no-repeat padding-box;
}

.module-config-icon4 {
  height: 44px;
  width: 44px;
  border-radius: 50px;
  font-size: 14px;
  padding-top: 5px;
  vertical-align: center;
  margin-top: 10px;
  margin-left: 20px;
  text-transform: uppercase;
  background: #D5F3E7 0% 0% no-repeat padding-box;
}

.report-icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1 !important;
  text-align: center;
  margin: 0 auto;
  color: #FE5D5D;
}

.module-config-icon4 {
  height: 44px;
  width: 44px;
  border-radius: 50px;
  font-size: 14px;
  padding-top: 5px;
  vertical-align: center;
  margin-top: 10px;
  margin-left: 20px;
  text-transform: uppercase;
  background: #FDDBDB 0% 0% no-repeat padding-box;
}

@media (min-width: 354px) and (max-width: 767px) {
  .button-right {
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .module-config-icon {
    margin-left: 0px;
  }
  .module-config-icon1 {
    margin-left: 0px;
  }
  .module-config-icon2 {
    margin-left: 0px;
  }
  .module-config-icon3 {
    margin-left: 0px;
  }
  .module-config-icon4 {
    margin-left: 0px;
  }
}


@media (max-width: 475px) {
  .module-config-icon {
    font-size: 10px;
    height: 40px;
    width: 40px;
  }
  .module-config-icon1 {
    margin-left: 0px;
    height: 40px;
    width: 40px;
  }
  .module-config-icon2 {
    margin-left: 0px;
    height: 40px;
    width: 40px;
  }
  .module-config-icon3 {
    margin-left: 0px;
    height: 40px;
    width: 40px;
  }
  .module-config-icon4 {
    margin-left: 0px;
    height: 40px;
    width: 40px;
  }
  .smart-farm-icon {
    margin-top: -1px;
  }
  .daily-update-icon {
    margin-top: -3px;
  }
}

.mc-container {
  width: 100%;
  height: 600px;
  margin: 140px 0;
  background: #FFFFFF 0% 0%;
}

.toggle {
  align-items: center;
}

.cc-tab a:focus, .cc-tab a:hover {
  color: #50565F;
}

.cc-tab .farm-header-toggles {
  flex-grow: 1;
  text-decoration: none;
}

.cc-tab .plot-tog-active {
  border-bottom: 4px solid #22c687 !important;
}

.sa-popup-scroll {
  max-height: 90vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 425px) {
  .plot-tog-active .farm-tab-icon-color {
    color: #22C687;
  }

  .farm-tab-icon-color {
    color: #50565F;
  }

  .farm-tab {
    color: #22C687;
    text-transform: none;
    margin-left: 5px;
  }

  .farm-tab-yield {
    color: #22C687;
    text-transform: none;
    display: none;
  }
  .plot-tog-active .farm-tab-yield {
    color: #22C687;
    text-transform: none;
    display: unset;
    font-size: 10px;
  }
}

.sa-modal-box-scroll {
  max-height: 650px;
  overflow: auto;
}

@media (max-width: 375px) {
  .plot-tog-active .farm-tab-icon-color {
    color: #22C687;
  }

  .plot-tog-active .farm-tab-yield {
    color: #22C687;
    text-transform: none;
    display: unset;
    font-size: 9px;
  }
}

.toolTipTheme {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: 0 0 10px #e7e7e7;
  border: 0.3px solid #EDEDED;
}

.place-top {
  border-top-color: #FFFFFF !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

.farm-alert-box {
  border: 1px solid;
  border-radius: 10px;
  padding: 10px 20px;
  align-items: center;
  display: flex;
}

.farm-alert-user {
  height: 50px;
  margin: 30px 0 20px 0;
  align-items: center;
  display: flex;
}

@media (max-width: 375px) {
  .plot-tog-active .farm-tab-icon-color {
    color: #22C687;
  }

  .plot-tog-active .farm-tab-yield {
    color: #22C687;
    text-transform: none;
    display: unset;
    font-size: 9px;
  }
}

.farm-alert-level {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  margin: 64px 0px 0;
  padding: 40px;
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 320px) {
  .plot-tog-active .farm-tab-icon-color {
    color: #22C687;
    width: 9px;
  }
  .plot-tog-active .farm-tab-yield {
    color: #22C687;
    text-transform: none;
    display: unset;
    font-size: 9px;
  }
}


@media (max-width: 425px) {
  .plot-tog-active .farm-tab-icon-color-u {
    color: #22C687;
  }

  .plot-tog-active .farm-tab-daily-update {
    color: #22C687;
    text-transform: none;
    display: unset;
    font-size: 11px;
  }

  .farm-tab-daily-update {
    color: #22C687;
    text-transform: none;
    display: none;
  }
}

@media (max-width: 767px) {
  .plot-tog-active .farm-tab-icon-color-s {
    color: #22C687;
  }

  .plot-tog-active .farm-tab-setting {
    color: #22C687;
    text-transform: none;
    display: unset;
    font-size: 11px;
  }

  .farm-tab-setting {
    color: #22C687;
    text-transform: none;
    display: none;
  }
}

@media (max-width: 425px) {
  .plot-tog-active .farm-tab-icon-color-f {
    color: #22C687;
  }

  .plot-tog-active .farm-tab-farm {
    color: #22C687;
    text-transform: none;
    display: unset;
    font-size: 11px;
  }

  .farm-tab-farm {
    color: #22C687;
    text-transform: none;
    display: none;
  }
}

@media (max-width: 425px) {
  .plot-tog-active .plot-tab-icon-color {
    color: #22C687;
  }

  .plot-tog-active .plot-tab {
    color: #22C687;
    text-transform: none;
    display: unset;
    font-size: 11px;
  }

  .plot-tab {
    color: #22C687;
    text-transform: none;
    display: none;
  }
}


.custom-switch-p {
  margin-top: 17px;
  margin-left: 5px;
}


@media (max-width: 425px) {
  .custom-switch-p {
    margin-left: -48px !important;
    width: 30px;
    height: 30px;
  }

  .feature-module {
    margin-left: -16px !important;
    padding-top: 26px !important;
  }
  .config-setting-content {
    margin-left: 30px !important;
    width: 216px !important;
  }
}

@media (max-width: 375px) {
  .custom-switch-p {
    margin-left: -48px !important;
  }

  .feature-module {
    padding-left: 30px !important;
    font: Bold 20px/35px Poppins;
  }

  .feature {
    padding-left: 22px;
    font: Bold 20px/35px Poppins;
    padding-top: 8px;
    padding-bottom: 8px !important;
  }

  .config-setting-content {
    font-size: 11px;
    padding-left: 1px !important;
  }
}

@media (max-width: 375px) {
  .config-setting-content {
    width: 196px !important;
  }
}

@media (max-width: 320px) {
  .config-setting-content {
    width: 155px !important;
  }
}


.overflowY {
  overflow-y: scroll !important;
}


@media (max-width: 320px) {
  .custom-switch-p {
    margin-left: -45px !important;
  }
}

.table-align {
  text-align: center;
}

.pull-left-addExpense {
  float: left !important;
}

@media (max-width: 425px) {
  .pull-left-addExpense {
    margin-top: -35px;
  }
}

.pull-left-addIncome {
  float: left !important;
}

@media (max-width: 425px) {
  .pull-left-addIncome {
    margin-top: -35px;
  }
}

.pull-left-addYield {
  float: left !important;
}

@media (max-width: 425px) {
  .pull-left-addYield {
    margin-top: -35px;
  }
}

.navbar-brand {
  margin-left: -16px;
}

@media (max-width: 425px) {
  .navbar-brand {
    margin-left: -2px;
  }
}


.settings-icon-b {
  color: #000000;
}

.settings-icon {
  margin-right: 16px; 
  cursor: pointer; 
  margin-bottom: 72px;
  border: 2px solid #ccc; 
  border-radius: 50%; 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); 
  padding: 4px; 
  width: 32px !important;
  height: 32px !important;
}

.smart-farm-icon {
  margin-right: 16px; 
  cursor: pointer; 
  margin-bottom: 72px;
  border: 2px solid #ccc; 
  border-radius: 50%; 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); 
  padding: 8px;
  width: 40px;
  height: 40px;
}

.text-center.position-relative {
  position: relative; 
}

.grayout-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10; 
  color: #333; 
  font-weight: bold;
  border-radius: 4px;
}

.grayout-icon {
  font-size: 24px;
  margin-bottom: 8px; 
}

.grayout-message {
  font-size: 16px;
  text-align: center;
}

.lightbox {
  /*border: 1px solid #fff;*/
  text-decoration: none;
  /*border-radius: 2px;*/
  /*position: fixed;*/
  /*top: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  /*background: rgba(0, 0, 0, .5);*/
  color: #000000;
  z-index: 20;

}

.lightBoxCopy {
  border: 1px solid #fff;
  text-decoration: none;
  border-radius: 2px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5);
  color: #000000;
}

.toolbarLB {
  max-width: 205px;
  padding: 3px 8px;
  color: #000;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  font-size: 10px;

}

.closeLB {
  cursor: pointer;
  margin-left: 175px;
  color: #000000;
  font-size: 12px;
}

.lightbox .iframeContainer {
  vertical-align: middle;
  background: #CCC;
  padding: 2px;
  z-index: 20;

}

.lightbox.closed {
  display: none;
}


.ok-button {
  background-color: #50565F;
  margin-left: 158px;
  color: #fff;
  border-radius: 2px;
  font-size: 10px;
}


.up-arrow {
  display: inline-block;
  position: relative;
  text-decoration: none;
  border-radius: 2px;
  margin-top: 55px;
  margin-left: -205px;
  width: 200px;

}

.up-arrow:before {
  content: '';
  display: block;
  position: absolute;
  left: 140px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: white;
}

.alert-container-box {
  width: 100%;
}

.alert-container {
  display: flex;
}

.reset-btn {
  margin: 48px;
}

.dashboard-location-detail {
  justify-content: center !important;
}

.up-arrow:after {
  content: '';
  display: block;
  position: absolute;
  left: 141px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}

.mobile-show {
  display: none;
}

@media (min-width: 769px) {
  .auto-pull-right {
    float: right;
  }
}

.filter-top {
  display: flex;
  justify-content: space-between;
}

.search-right {
  display: flex;
  order: 2;
}

.filter-left-inner {
  display: flex;
  justify-content: space-between;
}

.filter-left {
  order: 1;
}

.xin-pull-right {
  margin-bottom: 16px;
  float: right;
}

@media (max-width: 768px) {
  .auto-pull-right {
    float: none !important;
    text-align: center;
    margin-top: 16px;
  }
  .empty-results-notification {
    margin-left: 0;
  }

  .labelSizeInfoGroupMembers {
    margin-top: 32px !important;
  }

  .labelSizeInfo-houseHold {
    margin-top: 32px !important;
  }

  .pis-add {
    width: 44px !important;
  }
  .filter-top {
    display: block;
  }
  .search-right {
    display: flex;
    order: 2;
    justify-content: space-between;
  }
  .plot-th-H {
    height: 80px;
  }
  .farm-tab-yield {
    display: none !important;
  }

  .react-datepicker__month-container {
    float: none !important;
  }
  .sa-modal-footer-btn {
    float: none !important;
  }
  .btn-sa-width {
    width: 100% !important;
  }
  .pull-left {
    flex-direction: row !important;
  }
  .auto-pull-right button {
    display: block;
    width: 100%;

  }
  .desktop-show {
    display: none;
  }
  .btn-sa-secondary-export {
    display: none;
  }
  .mobile-show {
    display: block !important;
    align-items: center;
    horiz-align: center;
  }
}


.labelUpload {
  margin: 0px 3px 6px 0px;
}

.labelUploadCSV {
  margin: 0px 6px 6px 0px;
  margin-top: 6px;
  margin-left: 8px;
}

.labelUploadParticipantCSV {
  margin: 0px 6px 6px 0px;
  margin-top: 6px;

}


.labelUploadIncome {
  margin: 0px 3px 0px 0px;
}

input[type="file"] {
  display: none;
}

.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5)

}

.lightBoxCopy {
  border: 1px solid #fff;
  text-decoration: none;
  border-radius: 2px;
  /*position: fixed;*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5);
  color: #000000;
}

.toolbarLB {
  max-width: 205px;
  padding: 3px 8px;
  color: #000;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  font-size: 10px;

}

.closeLB {
  cursor: pointer;
  margin-left: 175px;
  color: #000000;
  font-size: 12px;
}

.lightbox .iframeContainer {
  vertical-align: middle;
  background: #CCC;
  padding: 2px;
  z-index: 20;

}

.lightbox.closed {
  display: none;
}


.ok-button {
  background-color: #50565F;
  margin-left: 158px;
  color: #fff;
  border-radius: 2px;
  font-size: 10px;
}


.up-arrow {
  display: inline-block;
  position: relative;
  text-decoration: none;
  border-radius: 2px;
  margin-top: 55px;
  margin-left: -205px;
  width: 200px;

}

.up-arrow:before {
  content: '';
  display: block;
  position: absolute;
  left: 140px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: white;
}

.m-t-b-150 {
  margin: 150px 0;
}

.up-arrow:after {
  content: '';
  display: block;
  position: absolute;
  left: 141px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}

.pis-add-activityFields {
  width: 44px;
  height: 25px;
  margin-top: 8px;
}


.c3-circles-Trendline {
  display: none;
}

.weather-mobile-click {
  background: #f5f5f5 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border: .25px solid #70707000;
  border-radius: 20px;
  /*opacity: 0.4;*/
  display: none;
  justify-content: center;
  align-items: center;
  float: right;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.c3-line-Trendline {
  stroke-width: 2px !important;
  /* stroke-dasharray: 5,5 !important; */
}


.labelSize1 {
  margin-left: 10px;
  margin-top: 10px;
  font: normal normal normal 12px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.75;
}

.labelLeader {
  margin-left: 10px;
  margin-top: 10px;
  font: normal bold normal 16px/21px Poppins;
  letter-spacing: 0px;
  color: #02C170;
  opacity: 0.75;
}

.labelSize {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  width: auto;
  vertical-align: top;
  margin-right: 4px;
  margin-top: 10px;
  text-transform: capitalize;
}


.labelSize-HH {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  width: 170px;
  vertical-align: top;
  margin-right: 4px;
  margin-top: 10px;
}

.labelSizeMember1 {
  font-size: 14px;
}


.labelSizeMember2 {
  font-size: 12px;
  border-radius: 4px;
  opacity: 1;
}


.labelSizeParticipantLocation {
  font-size: 14px;
  border-radius: 4px;
  opacity: 1;
  color: #181D33;
}

.labelNameDown {
  font-size: 12px;
  border-radius: 4px;
  opacity: 1;
  color: #181D33;
  text-align: left;
  margin-right: 5px;
}

.labelSizeProgramCategory {
  font-size: 10px;
  border-radius: 4px;
  opacity: 1;
  color: #181D33;
  opacity: 0.48;
  margin-right: 85px;
}

.labelSizeParticipantName {
  font-size: 17px;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #181D33;
  font-weight: bold;
  width: 152px
}

.labelNameUp {
  font-size: 14px;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #181D33;
  font-weight: bold;
  width: 152px;
  margin-right: 5px;

}

.plot-alert-btn {
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
  color: #FFFFFF;
}

.chart {
  width: 100%;
  min-height: 200px;
}

.labelSizeParticipant {
  text-align: left;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-top: 4px;
}

.labelSizeProgram {
  text-align: left;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-left: 10px;
}

.labelSizeGroup {
  text-align: left;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-left: 20px;
}

.labelSizeGroup-mobile {
  text-align: left;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-left: 9px;
}

.labelSizeViewMore {
  text-align: left;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-left: 111px;
  margin-top: 28px;
  font: normal normal 300 10px/16px Poppins;
}

.labelSizeViewMore-mobile {
  text-align: left;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-left: 52px;
  margin-top: 16px;
  font: normal normal 300 10px/16px Poppins;
}

.labelSizeViewMoreDeviceCount {
  text-align: left;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-left: 165px;
  margin-top: 14px;
  font: normal normal 300 10px/16px Poppins;

}

.labelSizeViewMoreDashboard {
  text-align: left;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-left: 242px;
  font-size: 12px;
}


.labelSizeViewParticpant {
  text-align: left;
  letter-spacing: 0px;
  color: #A7A7C5;
  opacity: 1;
  margin-left: 251px;
  margin-top: -6px;
  font: normal normal 300 10px/16px Poppins;

}

.labelSizeMemberParticipant {
  border-radius: 4px;
  opacity: 1;
  color: #cd0e0e;
  width: 41px;
  height: 37px;
  margin-left: 31px;
  font-size: 30px;
  font-weight: 800;
}

.labelSizeMemberParticipant-mobile {
  border-radius: 4px;
  opacity: 1;
  color: #cd0e0e;
  width: 41px;
  height: 37px;
  margin-left: 22px;
  margin-top: -12px;
  font-size: 30px;
  font-weight: 800;
}

.labelSizeMemberGroup {
  border-radius: 4px;
  opacity: 1;
  color: #22C687;
  width: 41px;
  height: 37px;
  margin-left: 31px;
  font-size: 30px;
  font-weight: 800;
}

.labelSizeMemberGroup-mobile {
  border-radius: 4px;
  opacity: 1;
  color: #22C687;
  width: 41px;
  height: 37px;
  margin-left: 22px;
  margin-top: -12px;
  font-size: 30px;
  font-weight: 800;
}

.labelSizeMemberHousehold {
  border-radius: 4px;
  opacity: 1;
  color: #589EFF;
  width: 41px;
  height: 37px;
  margin-left: 31px;
  font-size: 30px;
  font-weight: 800;
}

.labelSizeMemberHousehold-mobile {
  border-radius: 4px;
  opacity: 1;
  color: #589EFF;
  width: 41px;
  height: 37px;
  margin-left: 22px;
  margin-top: -12px;
  font-size: 30px;
  font-weight: 800;
}

.labelSizeMemberProgram {
  border-radius: 4px;
  opacity: 1;
  color: #FF6F17;
  width: 41px;
  height: 37px;
  margin-left: 23px;
  font-size: 30px;
  font-weight: 800;
}

.labelSizeMemberProgram-mobile {
  border-radius: 4px;
  opacity: 1;
  color: #FF6F17;
  width: 41px;
  height: 37px;
  margin-left: 22px;
  margin-top: -12px;
  font-size: 30px;
  font-weight: 800;
}

.labelSizeInfo {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
}

.labelSizeInfoMembers {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
  margin-top: 26px;
}

.labelSizeInfoActivity {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
  margin-top: 26px;
}

.react-switch-bg {
  background: #22c687;
}

.threshold {
  justify-content: space-between !important;
  align-items: center !important;
}

.labelSizeInfoGroupMembers {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
  margin-top: 118px;
}

.labelSizeInfoGroupMembers-participant {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
}

.memberview {
  display: flex;
}

.labelSizeInfo-personal {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
}

.labelSizeInfo-houseHold {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
  margin-top: 373px;
}

.labelSizeInfo-houseHold-participant {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
}

.labelSizeInfo-locationInfo {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
  margin-top: 64px;
}

.labelSizeInfo-locationInfo-participant {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #589EFF;
  opacity: 1;
}

.groupInfo {
  top: 188px;
  left: 227px;
  width: 996px;
  height: 210px;
  opacity: 1;
}

/* .rs-calendar-month-dropdown-year{
    cursor: pointer;

}

.rs-calendar-month-dropdown-year:hover{
    color:#81b4ee ;
} */

.graph-report-btn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 18px 0px;
  opacity: 1;
  border: 0.4px solid #EDEDED;
  text-align: center;
  font: normal normal 600 14px/21px Poppins;
  text-transform: uppercase;
  letter-spacing: 0px;
  padding: 3px 9px 0px 9px;
  /* border-radius: 6px; */
  width: auto;
  height: 32px;
  transition: 0.4s;
  cursor: pointer;
  margin: 0px 0px 0px 6px;
}


.graph-report-btn2 {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.47999998927116394px solid #EDEDED;
  border-radius: 3px 3px;
  opacity: 1;
  border: solid 1px #e9e9ed;
  text-align: center;
  font: normal normal 600 14px/21px Poppins;
  text-transform: uppercase;
  letter-spacing: 0px;
  padding: 0px 9px 0px 9px;
  /* border-radius: 6px; */
  width: auto;
  height: 32px;
  transition: 0.4s;
  cursor: pointer;
  margin: 0px 0px 0px 6px;
}

.graph-report-btn:focus {
  outline: none;
  border: solid 1px #e9e9ed;
}

.graph-report-btn2:focus {
  outline: none;
  border: solid 1px #e9e9ed;
}

.trend-icon {
  padding-bottom: 2px;
  color: #3F4451;
}

.sa-modal-footer-btn-group {
  top: 861px;
  left: 1128px;
  width: 0px;
  height: 12px;
  border: 2px solid #C9CBCE;
  opacity: 1;
}

.searchBtn {
  margin-right: 20px;
}

.c3-line-Predictionline {
  stroke-width: 2px !important;
  stroke-dasharray: 6, 6 !important;
}


.cash-flow-report-type .table-container {
  margin-top: 0 !important;
}

.btn.btn-light.dropdown-toggle > span {
  font-size: 14px !important;
  text-transform: capitalize;
  color: #868DAA !important;
}

.btn.btn-light.dropdown-toggle {
  background: #FFFFFF !important;
  border: 0.75px solid #3F44511F !important;
}

.lable-design {
  top: 188px;
  left: 334px;
  width: 220px;
  font-size: 13px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.participant-view .sensor-details {
  width: 228px;
  height: 64px;
}

.participant-view {
  display: flex;
  flex-wrap: wrap;
}

.lable-designMemebersCount {
  top: 188px;
  left: 334px;
  width: 220px;
  font-size: 13px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 124px;
}

.searchButton {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px !important;
  opacity: 1;
  border: solid 1px #22C687 !important;
}

.dropdown-container {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

.dropdown-content {
  margin-top: -6px;
}

.dropdown-heading-dropdown-arrow path {
  d: path('M8 11L12 15 16 11');
  d: path('M8 11L12 15 16 11') attr(width);
}

.dropdown-heading-dropdown-arrow {
  padding-left: 8px;
}

.multi-select {
  --rmsc-h: 42px !important;
  font-size: 14px;
}

.multi-select .dropdown-heading-value {
  color: #868DAA !important;
}

.multi-select .sa-filter .dropdown-container {
  max-width: 35px !important;
}

.multi-select .options {
  max-height: 240px !important;
  overflow: auto;
}

.multi-select .gray {
  color: #9e9e9e !important;
  font-size: 15px;
}

.displaySearch {
  display: flex;
}

.test-class {
  height: 44px !important;
  margin-left: 5px;
  border-radius: 4px !important;
}

.pis-add-memberExportCSV {
  width: 80px;
}


.groupLeader {
  border-color: #0d58a3;
}

.field-officer-date-range .rs-picker-default .rs-picker-toggle {
  border: none !important;
}

.diviceCount {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #282C36;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 17px;
  font-weight: bold;
}

.dashboardMaleFemaleChart {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #282C36;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
  margin-right: 366px;
}

.dashboardParticipantsAnalysis {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #282C36;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
}

.diviceCountNumber {
  text-align: center;
  font: normal normal bold 30px/46px Poppins;
  letter-spacing: 0px;
  color: #02C170;
  opacity: 1;
  font-weight: bold;
  margin-top: 10px;
}

.diviceCountPhones {
  text-align: center;
  font: normal normal medium 24px/35px Poppins;
  letter-spacing: 0px;
  color: #02C170;
  opacity: 1;
  margin-top: -38px;
  font-size: 26px;
  font-weight: 500;
}

.diviceCountPhonesEn {
  text-align: center;
  font: normal normal medium 24px/35px Poppins;
  letter-spacing: 0px;
  color: #02C170;
  opacity: 1;
  margin-top: -33px;
  font-size: 11px;
  font-weight: 500;
}

#circle {
  width: 190px;
  height: 190px;
  border-radius: 105px;
  border: 1px solid #02C170;
  margin-top: -175px;
  margin-left: 14px;
}


#circleRed {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #c10202;
}

#circleBlue {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #103699;
}

#circleYellow {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #d0ce21;
}

#circleGreen {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #29bb09;
}

.exportDashboard {
  margin-right: 31px;
}

.summaryReport {
  margin-top: 44px;
  margin-right: 34px;
  text-align: center;
  font: normal normal normal 14px/16px Poppins;
  letter-spacing: 0px;
  color: #181D33;
  opacity: 0.28;
}

.dashboard {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000022;
  border-radius: 8px;
  opacity: 1;
  margin-right: 26px;
  margin-left: 24px;
}

.dashboardLocation {
  margin-left: 30px;
  width: 126px;
  text-align: left;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
  margin-top: -4px;
}

.dashboardLocation-summary {
  margin-left: 20px;
  width: 126px;
  text-align: left;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
  margin-top: -4px;
  font-size: 14px;
}

.dashboardLocationCount {
  margin-left: 18px;

  text-align: right;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 0.5;
  margin-top: 3px;
}

.dashboardLocationHeight {
  height: 47px;
  margin-top: -4px;
}

.partcipant-detailsAlign {
  margin-top: 10px;
}

.particpantMaximum {
  font-size: 17px;
  font-weight: bold;
}

.dropdown-heading {
  margin-top: -6px;
  height: 42px !important;
  border-style: none !important;
  background-color: transparent !important;
  padding-left: 10px !important;
  padding-right: 6px !important;
}

.dropdown-heading-value {
  width: 300px !important;
  line-height: 44px !important;
}

.xindicate .dashboard-main .half-list-container-dashBoard {
  padding-top: 0;
}

.source-selector .scheduling-mode-box {
  width: 230px !important;
}

.borderStyle {
  border: 1px solid #02C170;
}

.textColor {
  color: #000000;
}

.position-rela {
  position: relative;
}

.weather-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  border: 0.25px solid #70707000;
  border-radius: 8px;
  float: right;
  display: inline-block;
  width: 88px;
  padding: 4px;
  height: 88px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.weather-box-position {
  display: flex; /* Enable flexbox */
  align-items: center; /* Center items vertically */
  position: absolute;
  right: 12px;
  top: 12px;
}

.weather-icon-box {
  padding: 0;
  margin: 0;
  border: 1px solid #e7e7e7;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  justify-content: center;
  display: flex;
}

.weather-pop-mobile-heading {
  text-align: left;
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 0.32px;
  color: #000000;
  text-transform: uppercase;
  display: inline;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-20 {
  padding-bottom: 20px;
}

.sa-popup-content-maxTime {
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px; 
}

.time-inputs-container {
  flex: 1;
  display: flex;
  padding: 20px;
}

.selected-time-container {
  flex: 1;
  background-color: rgb(239, 239, 239);
  padding: 20px;
}

.informative-msg-container {
  text-align: center;
  margin-bottom: 30px; 
  margin-top: 20px;
}

.or-container {
  text-align: center;
  margin: 20px 0; 
}

.or-text {
  font-size: 18px;
  font-weight: bold;
  color: #333; 
}


.swap-box {
  display: inline-block;
  cursor: pointer;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1px;
  border-radius: 100%;
  height: 24px;
  width: 24px;
}

.p-20px {
  padding: 20px !important;
}

.weather-name {
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #22242A;
  opacity: 0.4;

}

.weather-box-heading {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*opacity: 1;*/
}

.weather-pop-tabs {
  font: normal normal normal 16px/30px Poppins;
  letter-spacing: 0px;
  color: #50565F;
  text-transform: capitalize;
  cursor: pointer;
  opacity: 0.8;
}

.weather-time {
  letter-spacing: 0px;
  color: #707070;
  text-transform: lowercase;
  opacity: 0.8;
  text-align: left;
  font-size: 12px;
}

.hr-line {
  border-right: 2px solid #70707042;
  padding: 0px;
}

.weather-mobile-icon-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.23999999463558197px solid #EDEDED;
  border-radius: 4px;
  opacity: 1;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(100%);
}

.weather-icon-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.weather-mobile-icon-name {
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #22C687;
  padding-left: 10px;
}

.weather-mobile-name {
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #22242A;
  opacity: 0.4;
}

.weather-mobile-box-temp {
  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #22242A;
  font-weight: 600;

}

.weather-box-temp {
  font: normal normal 600 14px/px Poppins !important;
  letter-spacing: 0px;
  color: #22242A;
  text-transform: uppercase;
}

.weather-container .c3 {
  max-height: 250px !important;
}

.limit-text-80 {
  display: block;
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.weather-icon-selected {
  filter: grayscale(0) !important;
  color: #22C687 !important;
  width: auto !important;
  padding: 0 10px;
}

.v-a-m {
  vertical-align: middle;
}

.test-m {
  margin: 0 150px;
}

.input-range__track--background {
  z-index: 2;
}

.input-range {
  width: 96% !important;
  margin: 0 auto !important;
}

#sensorListContainerMapData {
  margin-right: -16px !important;
  margin-left: 8px !important;
  margin-top: 82px;
}

.single-card-FarmList {
  -webkit-box-shadow: 0px 0px 8px #0000000A;
  -moz-box-shadow: 0px 0px 8px #0000000A;
  box-shadow: 0px 0px 8px #0000000A;
  border-radius: 8px;
  border: 0.23999999463558197px solid #EDEDED;
  background-color: #fff;
  margin-top: 30px;
}


.limit-char-fw {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.single-card-header-sensorDetails {
  padding: 16px;
  border-bottom: solid 1px #eee;
  text-align: center;
}


.sensor-details .sensor-icon {
  padding-top: 7px;
}

.max-content {
  width: max-content;
}

.card-header-text label {
  margin-bottom: 0 !important;
}

@media (max-width: 320px) {
  .smart-farm .limit-charater-farmname {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis !important;
    width: 150px;
  }
}

@media (max-width: 620px) {
  .container-toggle-btn {
    padding: 0 8px !important;
  }
}

.mo-list-view .limit-charater-farmname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  width: 150px;
}

.alert-box {
  border: 0.75px solid #3F44511F;
}

.centerImg {
  margin: auto;
  width: 100%;
}

@media (max-width: 768px) {
  .dashboard-layout-container {
    padding: 40px 15px !important;
  }
}

.map-data #rangeFilter {
  height: 36px !important;
}

.xindicate {
  .xindicate-container {
    padding: 0px;
  }

  .xindicate-dashBoard-card-summary-box {
    width: 100%;
    height: 148px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #6d49491a;
    border-radius: 5px;
    opacity: 1;
    padding-left: 10px;
    padding-top: 10px;
  }

  .xindicate-dashBoard-card-summary-full-box {
    padding: 24px;
  }

  .xindicate-sensor-iconParticipant {
    display: inline-block;
    font-size: 26px;
    width: 42px;
    height: 48px;
    text-align: center;
    float: left;
    vertical-align: super;
    margin-top: 32px;
  }

  .xindicate-sensor-data {
    float: right;
    padding-right: 8px;
    padding-top: 36px;
  }

  .xindicate-sensor-data-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .xindicate-sensor-value {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0;
    color: #22242A;
  }

  .xindicate-label-size-view-more {
    text-align: right;
    letter-spacing: 0px;
    color: #A7A7C5;
    opacity: 1;
    padding-right: 10px;
    padding-top: 117px;
    font: normal normal 300 10px/16px Poppins;
  }

  .xindicate-link-color {
    color: #A7A7C5;
  }

  .xindicate-label-size-member-participant {
    border-radius: 4px;
    opacity: 1;
    width: 100%;
    font: normal normal medium 18px/27px Poppins;
    font-weight: 800;
  }

  .xindicate-limit-chara-group-member-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .xindicate-label-size-participant-location {
    font: normal normal 600 12px/18px Poppins;
    border-radius: 4px;
    opacity: 1;
    color: #A7A7C5;
  }

  .xindicate-card-summary-img-no-boarder-dashboard {
    width: 56px;
    height: 56px;
  }

  .xindicate-row-margin {
    padding-top: 18px;
  }

  .xindicate-parti-color {
    color: #FF4343;
  }

  .xindicate-group-color {
    color: #22C687;
  }

  .xindicate-hou-ho-color {
    color: #589EFF;
  }

  .xindicate-pro-color {
    color: #FF6F17;
  }

  @media (max-width: 425px) {
    .xindicate-dashBoard-card-summary-full-box-mobile {
      display: flex;
      justify-content: space-evenly;
    }
  }
  @media (max-width: 320px) {
    .xindicate-sensor-data {
      padding-right: 21px !important;
    }
    .xindicate-participant-detail {
      grid-template-columns: 40% 60% !important;
    }
  }

  @media (max-width: 375px) {
    .xindicate-hide {
      display: none !important;
    }
    .xindicate-sensor-data {
      margin-top: -48px;
    }
    .xindicate-label-size-member-participant-align {
      width: 100%;
      text-align: center;
    }
    .xindicate-sensor-iconParticipant {
      margin-top: 0px;
    }
  }

  .xindicate-dashboard-items-display-grid {
    display: grid;
    grid-template-columns: 50% 15% 35%;
    grid-template-rows: auto;
    grid-template-areas:
    "map location dashboardLocation"
    "map participantAnalysis participantAnalysis"
  }

  .xindicate-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  @media (min-width: 768px) {
    .xindicate-participant-detail {
      grid-template-columns: 15% 85% !important;
    }
  }
  @media (min-width: 769px) {
    .xindicate-participant-detail {
      grid-template-columns: 45% 55% !important;
    }
  }
  @media (min-width: 990px) {
    .xindicate-participant-detail {
      grid-template-columns: 35% 65% !important;
    }
  }

  @media (min-width: 1200px) {
    .xindicate-participant-detail {
      grid-template-columns: 25% 85% !important;
    }
  }

  @media (max-width: 767px) {
    .xindicate-summary-box-dash-board {
      margin-top: 16px;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    .xindicate-dashboard-items-display-grid {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-template-areas:
      "map"
      "location"
      "dashboardLocation"
      "participantAnalysis"
    }
    .xindicate-location-map-box {
      margin-left: 0px !important;
    }
    .xindicate-card-summary-box-dashBoard-participant-name {
      margin-left: 0px !important;
    }
  }
  @media (min-width: 769px) {
    .xindicate-card-summary-box-dashBoard-participant-name {
      width: 96% !important;
      margin-left: 14px;
    }
    .xindicate-location-map-box {
      width: 97% !important;
      margin-left: -14px;
      padding-right: 8px !important;
    }
  }

  @media (max-width: 1024px) {
    .xindicate-card-summary-box-participant-age-range {
      margin-top: 16px !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
  @media (min-width: 788px) and (max-width: 1069px) {
    .xindicate-circle-red {
      padding-left: 0px;
    }
    .xindicate-dashboard-location-count {
      margin-top: -2px !important;
    }
    .xindicate-dashboard-location-summary {
      font-size: 10px !important;
    }

  }

  .xindicate-hide {
    display: block;
  }

  .xindicate-summary-box-dash-board {
    width: 100%;
    height: 314px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    box-shadow: 0px 2px 4px #6d49491a;
    border-radius: 6px;
    text-align: center;
  }

  .xindicate-circle-border {
    width: 170px;
    height: 170px;
    border-radius: 105px;
    border: 1px solid #02C170;
    padding-top: 12px;
  }

  .xindicate-no-boarder-phone {
    width: 60px;
    height: 60px;
  }

  .xindicate-device-img-count {
    grid-area: device;
    justify-self: center;
  }

  .xindicate-device-count {
    grid-area: deviceCount;
    justify-self: center;
    padding-top: 30px;
    font: normal normal 600 16px/25px Poppins;
  }

  .xindicate-display-grid {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-template-rows: auto;
    grid-template-areas:
    ".deviceCount."
    ". device .";
  }

  .xindicate-map {
    grid-area: map;
    justify-self: center;
  }

  .xindicate-location {
    grid-area: location;
    justify-self: center;
  }

  .xindicate-dashboardLocation {
    grid-area: dashboardLocation;
    justify-self: center;
  }

  .xindicate-participantAnalysis {
    grid-area: participantAnalysis;
    justify-self: center;
  }

  .xindicate-profile {
    grid-area: profile;
    justify-self: center;
  }

  .xindicate-profileDetail {
    grid-area: profileDetail;
    justify-self: center;
  }

  .xindicate-participant-detail {
    margin-left: 8px;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-areas:
    "profile profileDetail"
  }

  .xindicate-p-a-24 {
    padding: 24px;
  }

  .xindicate-green-color {
    color: #02C170;
  }

  .xindicate-gap {
    display: grid;
    grid-column-gap: 16px;
  }

  .xindicate-card-summary-box-participant-age-range {
    width: 100%;
    height: 314px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px;
    box-shadow: 0px 2px 4px #6d49491a;
    border-radius: 6px;
  }

  .xindicate-dashboard-male-female-chart {
    text-align: left;
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #282C36;
    text-transform: uppercase;
    opacity: 1;
    font-weight: bold;
  }

  .xindicate-participant-m {
    margin-left: 18px;
  }

  .xindicate-dashboard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000022;
    border-radius: 8px;
    opacity: 1;
  }

  .xindicate-location-map-box {
    width: 100%;
    height: 685px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px;
    margin-top: 16px;
    box-shadow: 0px 2px 4px #6d49491a;
    border-radius: 6px;
  }

  .xindicate-card-summary-box-dash-board-location {
    width: 100%;
    height: 317px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px;
    box-shadow: 0px 2px 4px #6d49491a;
    border-radius: 6px;
    margin-top: 16px;
  }

  .xindicate-dashboard-location-count {
    display: flex;
    justify-content: flex-end;
    font: normal normal normal 13px/20px Poppins;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 0.5;
    margin-top: -2px;
  }

  .xindicate-dashboard-location-summary {
    margin-left: 6px;
    width: 126px;
    text-align: left;
    letter-spacing: 0px;
    color: #43425D;
    opacity: 1;
    margin-top: -3px;
    font-size: 14px;
    text-transform: uppercase;
  }

  .xindicate-circle-red {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #c10202;
  }

  .xindicate-card-summary-box-dashBoard-participant-name {
    width: 100%;
    height: 317px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px;
    box-shadow: 0px 2px 4px #6d49491a;
    border-radius: 6px;
    margin-top: 16px;
  }

  .xindicate-partcipant-details {
    width: 100%;
    height: 80px;
    padding: 8px;
    margin-bottom: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #0000000A;
    border: 0.25px solid #EDEDED;
    border-radius: 8px;
  }

  .xindicate-sensor-icon-profile {
    display: inline-block;
    color: #FF4343;
    font-size: 26px;
    width: 59px;
    height: 59px;
    text-align: center;
  }

  .xindicate-icon-tab-profile {
    width: 100%;
    height: 100%;
    border-radius: 29px;
  }

  .xindicate-farm-tab-icon-color {
    color: #50565F;
  }

  .xindicate-participant-data {
    display: inline-block;
    width: 153px;
  }

  .xindicate-limit-charater-view-member-card {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  .xindicate-label-name-up {
    font-size: 14px;
    border-radius: 4px;
    opacity: 1;
    text-align: left;
    letter-spacing: 0px;
    color: #181D33;
    font-weight: bold;
    width: 100%;
    padding-top: 10px;
  }

  .xindicate-program-label-name-up-size {
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: bold;
    padding-top: 20px;
  }

  .xindicate-label-name-down {
    font-size: 12px !important;
    padding-top: 35px;
  }

  .xindicate-label-size-view-more-dashboard {
    display: flex;
    justify-content: flex-end;
    letter-spacing: 0px;
    color: #A7A7C5;
    opacity: 1;
    font-size: 12px;
    padding-top: 12px;
  }

  .xindicate-card-summary-box-dashboard-participant-analysis {
    width: 100%;
    height: 350px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px;
    box-shadow: 0px 2px 4px #6d49491a;
    border-radius: 6px;
    margin-top: 16px;
  }

  .xindicate-dashboard-participants-analysis {
    text-align: left;
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #282C36;
    text-transform: uppercase;
    opacity: 1;
    font-weight: bold;
  }

  .xindicate-divice-count-number {
    text-align: center;
    font: normal normal bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #02C170;
    opacity: 1;
    font-weight: bold;
  }

  .xindicate-label {
    margin-bottom: 0px;
  }

  .xindicate-dashboard-card-p {
    padding-left: 0px !important;
    padding-right: 0px !important
  }

  .xindicate-p-b-16 {
    padding-bottom: 16px;
  }

  .xindicate-full-list-view {
    height: 100%;
    padding-top: 77px;
    overflow: auto;
  }

  .xindicate-half-list-view {
    height: 100%;
    padding-top: 77px;
  }

  @media (max-width: 620px) {
    .xindicate-full-list-view {
      padding-top: 77px !important;
    }
  }
}


@media(max-width: 485px) {
  .farm-tab-setting {
    display: none !important;
  }
  .farm-tab-farm {
    display: none !important;
  }
  .plot-tab {
    display: none !important;
  }
}

.farm-tab-parent a:first-child div:first-child {
  padding-left: 10px;
}

.min-w-220 {
  min-width: 220px !important;
}

.farm-tab-parent a:last-child div:first-child {
  padding-right: 10px;
}


.icon-color-blue {
  color: #007bff;
}


@media(min-width: 1000px) and (max-width: 1400px) {

  .duplication {
    min-width: 36px !important;
    border-radius: 4px;
    opacity: 1;
    height: 32px;
    width: 140px;
    font-size: 14px;
  }

}

.sa-table-btn-duplication {
  min-width: 36px !important;
  border-radius: 4px;
  opacity: 1;
  height: 32px;

  font-size: 14px;
}


.rs-picker-default .rs-picker-toggle.rs-btn {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media(max-width: 425px) {

  .side-menu-btn {
    margin-right: 27px;
  }


  .sa-table-m-l-0 {
    margin-left: 0px !important;
  }

}

.m-l-0 {
  margin-left: 0px !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.sa-table-head-color {
  color: rgba(0, 0, 0, 0.5);
}


.notification-element-space {
  margin-left: auto;
  margin-right: -28px !important;
}

@media (min-width: 770px) and (max-width: 992px) {
  .notification-element-space {
    margin-left: auto !important;
    margin-right: -14px !important;
  }
}

@media(max-width: 768px) {
  .row-gap-mobile {
    row-gap: 20px;
  }


  .assign-task-w {
    width: 36px;
  }

}


@media (max-width: 320px) {

  .sa-table-filter {
    flex-direction: column !important;
  }
  .sa-table-position {
    position: relative;
  }
  .sa-table-btn-position {
    position: absolute;
    right: 0;
  }
  .sa-filter-row {
    flex-direction: row !important;
  }
  .sa-participant-search {
    width: 67% !important;
  }

}

.goog-te-gadget-icon {
  margin-right: 7px;
}

@media (max-width: 425px) {
  .sa-table-filter {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .sa-export-row {
    flex-direction: row !important;
  }
  .sa-filter-report {
    margin-left: 8px !important;
    margin-top: 0px;

  }
}

.setting-element-space {
  margin-right: -28px !important;
  flex-wrap: nowrap !important;
}

@media (min-width: 769px) and (max-width: 992px) {
  .setting-element-space {
    margin-left: auto !important;
    margin-right: -14px !important;
  }
}

.setting-title {
  font: normal 14px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-left: 66px;
}

.setting-description {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-left: 66px;
}


@media(max-width: 768px) {
  .single-card-content-setting {
    width: 411px !important;
    margin-right: -64px !important;
    margin-top: 17px !important;
  }
}

@media(max-width: 425px) {
  .single-card-content-setting {
    margin-right: -68px !important;
  }
}

@media(max-width: 400px) {
  .single-card-content-setting {
    width: 374px !important;
  }
}

@media(max-width: 375px) {
  .single-card-content-setting {
    width: 361px !important;
  }
}

@media(max-width: 320px) {
  .single-card-content-setting {
    width: 306px;
  }
}


.fms-icon {
  height: 24px;
  width: 24px;
  margin-left: 9px;
  color: #02C170;
}

.bird-view-icon {
  height: 24px;
  width: 24px;
  margin-top: 4px;
  // margin-left: -3px;
}


.update-size {
  width: 151px;
}

@media(max-width: 320px) {
  .update-size {
    width: 100%;
  }
}


.advance-m {
  margin: 0px 16px 0px 16px;
}

.overflow-initial {
  overflow: initial;
}

.sensor-width {
  width: 425px;
  margin: 0px 8px 0px 8px;
}

.sensor-add-width {
  width: 500px;
  margin: 0px 8px 0px 8px;
}

@media(min-width: 426px) and (max-width: 767px) {
  .sensor-width {
    width: 100%;
  }
  .sensor-value {
    font-size: 14px;
  }
  .limit-sensorName-sa {
    width: 93px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .sensor-width {
    width: 50%;
  }
  .sensor-value {
    font-size: 14px;
  }
  .limit-sensorName-sa {
    width: 93px;
  }
  .card-header-text-2 .heading {
    width: 100px;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 992px) {
  .gap-mb-24 {
    gap: 24px;
    row-gap: 24px;
  }
}

@media(min-width: 621px) {
  .sensor-width {
    margin: 0px 8px 0px 8px;
  }
}

@media(max-width: 425px) {
  .sensor-details {
    padding: 0px 8px 0px 8px;
  }
  .sa-wastage-filter-m {
    margin: 0px;
  }
  .monthly-report {
    position: static !important;
  }
  .sa-wastage-filter-m {
    margin-left: 8px !important;
  }
}

@media(max-width: 320px) {
  .sensor-details {
    padding: 8px;
  }
}

.monthly-report {
  position: relative;
}

.monthly-report-absolute {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

@media(max-width: 374px) {
  .pis-add {
    width: 40px;
  }
}

@media(max-width: 767px) {
  .monthly-report-absolute {
    position: static;
    margin-left: auto;
    margin-right: auto;
    transform: translate(0);
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .overflow-initial {
    overflow: scroll;
  }
  .mh-md-100 {
    max-height: calc(100vh - 100px) !important;
  }
}

.m-t-52 {
  margin-top: 52px;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 5px !important;
}

.icon-add-btn {
  background-color: #22C687;
  color: white;
  height: 48px !important;
  width: 48px !important;
  cursor: pointer;
  margin-bottom: 40px;
  border-radius: 50px;
}

.farm-add-btn {
  position: fixed;
  top: 90%;
  right: 5%;
  float: right;
  margin-right: 0px;
  overflow: auto;
}

.corporate-actions-icon {
  color: #000000;
  width: 20px;
  height: 20px;
  opacity: 0.6;
}

.sa-corporate-action-flex {
  display: flex;
  float: right;
}

.fqdMVT.is-disabled {
  border: none !important;
}

.corporate-action-icon-size {
  margin-left: 8px;
  width: 20px;
  height: 20px;
  color: #707070 !important;
}

.corporate-delete-icon {
  margin-left: 8px;
  width: 20px;
  height: 20px;
  color: var(--warning) !important;
}

.table-bg-table {
  padding: 16px;
  margin-bottom: 30px;
}

.devices-action {
  margin-top: 8px !important;
}

.m-r-6 {
  margin-right: 6px !important;
}

.sensor-kit-main {
  position: relative;
}

.sensor-kit {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.sensor-kit-add {
  position: absolute;
  left: 45%;
  transform: translate(-50%);
}

.toggle-icon-in-btn {
  width: 16px !important;
  height: 16px !important;
}

.w-150 {
  width: 150px !important;
}

.w-180 {
  width: 180px !important;
}

.h-32 {
  height: 32px !important;
}

.h-36 {
  height: 36px !important;
}

.br-4 {
  border-radius: 4px !important;
}

.actuator-tog-w-h {
  height: 32px;
}

@media(max-width: 768px) {
  .toggle-icon-in-btn {
    margin-left: -8px;
    margin-top: -4px;
  }
  .icon-add-btn {
    margin-bottom: 40px;
    border-radius: 50px;
  }
  .farm-add-btn {
    position: fixed;
    top: 90%;
    right: 2%;
  }
  .actuator-tog-w-h {
    width: 32px;
  }
  .sensor-kit-m {
    margin-top: 16px;
  }
}

.sensor-kit-m {
  margin-bottom: 16px;
}

#kit-m {
  margin: 0px -8px;
}


.sa-filter-icon-size {
  height: 15px !important;
  width: 15px !important;
  margin-right: 6px;
}

.household-stepper-container {
  gap: 100px;
  position: relative;
}

@media(max-width: 768px) {
  .household-stepper-container {
    gap: 75px;
  }
  .w-120 {
    width: 120px;
  }
}

@media(max-width: 425px) {
  .household-stepper-container {
    gap: 12px;
  }
}

@media(max-width: 375px) {
  .stepper-line {
    width: 60px;
  }
}

.m-l-a {
  margin-left: auto;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-20 {
  margin-top: 20px;
}

.height-auto {
  height: auto !important;
}

@media(min-width: 992px) {
  .household-w {
    width: 143px !important;
  }
}

.sa-evaluation-icon-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ff-powered-img {
  display: none;
}

.confirmation-z-index {
  z-index: 9999;
}

.sa-qr-box-style {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  opacity: 1;
  max-width: 350px;
  max-height: 600px;
  overflow: auto;
  margin-top: 50px;
}

.sa-qr-header {
  margin-bottom: 9px;
  padding-top: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: 0px 4px 30px #00000008;
  background: #22C687 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 60px;
}

.sa-qr-heading {
  font: normal normal normal 20px/30px Poppins;
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sa-qr-content {
  width: calc(100% - 16px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  border: solid #EEEEEE 0.5px;
  border-radius: 10px;
  margin-bottom: 8px;
}

.participant-qr-profile-inner {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #FFFFFF;
}

.qr-profile-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}

.qr-profile {
  border-radius: 50%;
  overflow: hidden;
  border: 1px dashed #C9C9C9;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.qr-name {
  width: 374px;
  text-align: center;
  font: normal normal bold 18px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 16px;
  margin-top: 8px;
}

.sa-qr-footer {
  padding-top: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: 0px 4px 30px #00000008;
  background: #D6D6D6 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 60px;
  color: #000000;
}

.sa-qr-footer-normal-text {
  text-align: center;
  font: normal normal normal 8px Poppins;
  letter-spacing: 1.67px;
  color: #000000;
  opacity: 1;
}

.sa-qr-footer-head-text {
  text-align: center;
  font: normal normal bold 12px Poppins;
  letter-spacing: 2px;
  color: #000000;
  opacity: 1;
}

.qr-text-box {
  width: 459px;
  opacity: 1;
  text-align: center;
}

.qr-key {
  text-align: right;
  font: normal bold 12px Poppins;
  letter-spacing: 1.5px;
  color: #000000;
  opacity: 1;
}

.qr-value {
  text-align: left;
  margin-left: 16px;
  font: normal normal 12px Poppins;
  letter-spacing: 1.5px;
  color: #5C5C5C;
  opacity: 1;
}

.crop-toggle-txt {
  margin-top: 16px;
  cursor: pointer;
  font: normal normal normal 14px/19px Poppins;
  letter-spacing: 0px;
  color: #707070;
  display: flex;
  gap: 10px;
}

.crop-agro-card-img-container {
  width: 60px;
  height: 60px;
  border: 0.23999999463558197px solid #70707080;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crop-agro-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  border-radius: 8px;
  width: 232px;
  height: 76px;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.crop-agro-card-txt {
  font: normal normal 600 16px/25px Poppins;
  color: #181D33;
}

.crop-agro-card-sub-txt {
  font: normal normal medium 14px/21px Poppins;
  color: #181D33A3;
}

.crop-toggle-icon {
  width: 21px;
  height: 20px;
  background: #22C687 0% 0% no-repeat padding-box;
  border-radius: 100%;

}

.toggle-icon-active {
  filter: invert(59%) sepia(93%) saturate(376%) hue-rotate(98deg) brightness(84%) contrast(100%);
}

.crop-toggle-icon-arrow {
  color: #22C687;
  width: 16px;
}

.crop-agronomy-list {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 16px;
  flex-flow: wrap;
}

.pdf-download-btn {
  width: 140px;
  height: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000000A;
  border: 0.25px solid #EDEDED;
  border-radius: 8px;
  font: normal normal  14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px !important;
  cursor: pointer;
}

.crop-agronomy-heading {
  font: normal normal 600 16px/27px Poppins;
  letter-spacing: 0px;
  color: #22C687;
  text-align: center;
  overflow: hidden;
}

.crop-agronomy-heading > span {
  position: relative;
  display: inline-block;
}

.crop-agronomy-heading > span:before, .crop-agronomy-heading > span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 0.47999998927116394px solid #CECACA;
  width: 591px; /* half of limiter*/
  margin: 0 20px;
}

.crop-agronomy-heading > span:before {
  right: 100%;
}

.crop-agronomy-heading > span:after {
  left: 100%;
}

.scan-text {
  width: 479px;
  text-align: center;
  font: normal normal 8px Poppins;
  letter-spacing: 0.92px;
  color: #000000;
  opacity: 1;
  align-items: center;
  margin-bottom: 20px;
}

.participant-qr-style {
  width: 160px;
  height: 160px;
  overflow: hidden;
}

.qr-img {
  overflow: hidden;
  margin: 16px auto;
}

.qr-multi-selector {
  padding-left: 0 !important;
}

.align-center {
  align-items: center;
  justify-content: center;
  margin: auto;
}

.assign-task-w {
  width: 126px;
}

@media(max-width: 768px) {
  .assign-task-w {
    width: 36px;
  }
}

.border-none {
  border: none !important;
}

@page {
  size: legal !important;
  margin: 0;
}

.keells-pdf {
  display: none;
}


@media print {
  //@page {
  //  size: auto;
  //  margin: 0;
  //}

  .keells-pdf {
    display: block;
  }

  .keells-hide {
    display: none !important;
  }
  .print-hide {
    display: none !important;
  }
  .print-visible {
    overflow: visible !important;
  }
  .stage-display {
    display: block;
  }
  .sa-qr-print-style-300 {
    margin-bottom: 300px !important;
  }
  .sa-qr-print-style-180 {
    margin-bottom: 180px !important;
  }

  @media (max-width: 620px) {
    .xindicate-full-list-view {
      padding-top: 0px !important;
    }
  }

}


@media(max-width: 767px) {
  .qr-multi-selector {
    margin-top: 30px;
  }
}

.evaluation-img {
  width: 30px;
  height: 30px;
}

.green-color {
  color: #22C687;
}

.w-154 {
  width: 154px;
}

.height-100 {
  height: 100px !important;
}

.crop-agronomy-view-box {
  min-height: 238px;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  margin-top: 8px;
  overflow-y: scroll;
}

.connector-number {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  border: 1px solid #000000;
  text-align: center;
  font: normal normal normal 12px/30px Poppins;
  letter-spacing: 0px;
  color: #000000;
  cursor: pointer;
  background-color: #f1f1f1;
}

.connector-number-active {
  height: 40px;
  width: 40px;
  border: 2px solid #4ED19F;
  font: normal normal 600 12px/30px Poppins;
  letter-spacing: 0px;
  color: #4ED19F;
  cursor: pointer;
  background-color: #f1f1f1;
}

.connector-txt {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  cursor: pointer;
}

.connector-txt-active {
  color: #4ED19F;
  font: normal normal 600 16px/25px Poppins;
  cursor: pointer;
}

.crop-view-head {
  width: 250px;
  height: 23px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #181D33;
  opacity: 1;
}

.crop-w-15per {
  width: 15%;
}

.w-73 {
  width: 73px;
}

.crop-agronomy-sensor {
  justify-content: space-around;
}

.connector-container {
  gap: 100px;
  position: relative;
}

@media(max-width: 724px) {
  .crop-w-15per {
    width: 35%;
  }
}

@media(max-width: 768px) {
  .connector-container {
    gap: 20px;
  }
  .px-web-32 {
    padding: 0 !important;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .connector-container {
    gap: 40px;
  }
}

.flex {
  display: flex;
}

.green-dot {
  height: 8px;
  width: 8px;
  background-color: #22C687;
  border-radius: 100%;
}

.agronomy-practices-text {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.crop-arrow {
  margin-bottom: -46px;
}

.padding-4 {
  padding: 4px !important;
}

.photo-upload-background {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: grid;
  opacity: 1;
  top: 168px;
  left: 350px;
  width: 600px;
  height: 80px;
  padding: 5px 0px 0px 120px;
  grid-template-columns: 60px auto;
}

.photo-upload {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: grid;
  opacity: 1;

  grid-template-rows: 25px auto;
}

.photo-upload-img {
  top: 176px;
  left: 354px;
  width: 64px;
  height: 64px;

}

.photo-upload-name {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #757575;
  opacity: 1;
}

.photo-upload-size {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #757575;
  opacity: 0.6;

}

.informative-msg {
  position: relative !important;
  margin-left: 46px;
  margin-bottom: 24px;
}

.informative-msg-icon {
  color: #707070;
  margin-left: -38px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fo-monthly-report-lable {
  text-transform: none;
  text-align: center;
  font: normal 12px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.fo-monthly-report-table-box {
  max-height: 307px;
  margin-bottom: 16px;
  padding: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  border-radius: 4px;
  opacity: 1;
}

.fo-dashboard-box {
  display: inline-block;
  width: 1269px;
  height: 57px;
  opacity: 1;
  margin-top: 12px;
}

.fo-dashboard-head {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #22242A;
  opacity: 1;
}

.fo-dashboard-icon {
  display: inline-block;
  font-size: 26px;
  width: 42px;
  height: 42px;
  text-align: center;
  vertical-align: middle;
  background-color: #0000000A;
  border-radius: 50%;
}

.fo-table-head {
  margin-left: 10px;
  font: bold 14px Poppins;
  letter-spacing: 0px;
  color: #22242A;
  opacity: 1;
  display: inline-block;
}

.fo-chevron-size {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  display: inline-flex;
}

.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.m-h-238 {
  max-height: 238px;
}

@media(min-width: 434px) and (max-width: 493px) {
  .location-filter-m {
    margin-top: 0px;
  }
}

@media(min-width: 426px) and (max-width: 428px) {
  .location-filter-m {
    margin-top: 8px;
  }
}

.connector-hr-line {
  margin-bottom: -18px;
  margin-left: -130px;
  width: 160px;
  max-width: 225px;
  border-top: 1px solid #707070;
}

@media(max-width: 991px) {
  .connector-hr-line {
    margin-left: -70px;
    width: 100px;
  }
}

@media(max-width: 767px) {
  .connector-hr-line {
    margin-left: -50px;
    width: 75px;
  }
}

@media(max-width: 425px) {
  .connector-hr-line {
    margin-left: -60px;
  }
}

.stages-container {
  margin-top: 142px;
}

@media print {
  @media (max-width: 620px) {
    .xindicate .xindicate-full-list-view {
      padding-top: 0px !important;
    }
  }
}



.stage-display {
  display: none;
}

@media print {
  .stage-display {
    display: block;
    width: 100%;
  }
}

.print-satge-name {
  font: normal normal medium 14px/21px Poppins;
  font-weight: 600;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.connector-hr-line-for-stable-stages {
  width: 206px;
  margin-bottom: -18px;
  margin-left: -160px;
  margin-right: 40px;
  border-top: 1px solid #707070;
}

@media(max-width: 991px) {
  .connector-hr-line-for-stable-stages {
    width: 156px;
    margin-left: -98px;
  }
}

@media(max-width: 425px) {
  .connector-hr-line-for-stable-stages {
    width: 101px;
    margin-left: -98px;
  }
}

.connector-hr-line-for-buyer-stages-left {
  width: 220px;
  margin-bottom: -18px;
  margin-left: -150px;
  margin-right: 110px;
  border-top: 1px solid #707070;
}

.connector-hr-line-for-buyer-stages-right {
  width: 250px;
  margin-bottom: -18px;
  margin-left: -180px;
  margin-right: 110px;
  border-top: 1px solid #707070;
}

@media(max-width: 991px) {
  .connector-hr-line-for-buyer-stages-left {
    width: 160px;
    margin-left: -90px;
  }

  .connector-hr-line-for-buyer-stages-right {
    width: 190px;
    margin-left: -120px;
  }
}

@media(max-width: 768px) {
  .connector-hr-line-for-buyer-stages-left {
    width: 140px;
    margin-left: -70px;
  }

  .connector-hr-line-for-buyer-stages-right {
    width: 180px;
    margin-left: -110px;
  }
}

.connector-hr-line-for-stable-stages-hh {
  width: 200px;
  margin-bottom: -18px;
  margin-left: -158px;
  margin-right: 40px;
  border-top: 1px solid #707070;
}

@media(max-width: 991px) {
  .connector-hr-line-for-stable-stages-hh {
    width: 156px;
    margin-left: -98px;
  }
}

@media(max-width: 555px) {
  .connector-hr-line-for-stable-stages-hh {
    width: 106px;
    margin-left: -98px;
  }
}

@media(max-width: 375px) {
  .connector-hr-line-for-stable-stages-hh {
    width: 100px;
    margin-left: -90px;
  }
}

@media(max-width: 991px) {
  .connector-hr-line-for-stable-stages-import {
    width: 161px;
    margin-left: -149px;
  }
}

@media(max-width: 425px) {
  .connector-hr-line-for-stable-stages-import {
    width: 118px;
    margin-left: -105px;
  }
}

.fixed-add-btn {
  bottom: 0;
  right: 42px;
  position: fixed;
}

.plot-setting-btn-m {
  margin-right: 16px;
}

@media(max-width: 375px) {
  .plot-setting-btn-m {
    margin-right: 0px;
  }
}

@media(max-width: 320px) {
  .plot-setting-btn-m {
    margin-bottom: 8px;
  }
}

.height-20 {
  height: 20px;
  width: 80px;
}

.overflowY-scroll {
  overflow-y: scroll;
}

.word-break {
  word-break: break-all;
}

@media(max-width: 767px) {
  .farm-tab-setting-display {
    display: none;
  }
}

.crop-agro-calc-height {
  height: calc(100vh - 350px);
}

.xindicate-calc-height {
  height: calc(100vh - 240px);
}

.xindicate-report-calc-height {
  height: calc(100vh - 374px);
}

.xindicate-report-calc-max-height {
  max-height: calc(100vh - 130px);
}

.cash-flow-crop-table-calc-height {
  height: calc(100vh - 461px);
}

.cash-flow-table-calc-height {
  height: calc(100vh - 426px);
}

@media(min-width: 388px) and (max-width: 492px) {
  .user-filter {
    margin: 0px 0px 8px 8px !important;
  }
}

.add-farmer-head {
  height: 50px;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #3F4451;
  opacity: 1;
}

.add-lable-color {
  color: #707070;
}

.evauation-report-calc-height {
  height: calc(100vh - 450px);
}

.corporate-user-calc-height {
  height: calc(100vh - 350px);
}

.ccr-user-calc-height {
  height: calc(100vh - 280px);
}

.corporate-water-calc-height {
  height: calc(100vh - 291px);
}

.farm-user-calc-height {
  height: calc(100vh - 505px);
}

.bulk-import-farmer-calc-height {
  height: calc(100vh - 472px);
}

.device-detail-popup-calc-height {
  height: calc(100vh - 411px);
}

.cash-flow-btn-p {
  padding-top: 5px;
  padding-left: 7px;
}

.export-container {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.export-head {
  padding-left: 10px;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.checkout-box {
  top: 373px;
  left: 360px;
  width: 21px;
  height: 20px;
  border: 0.5px solid #C7C4C4;
  border-radius: 4px;
  opacity: 1;
}

.export-checkbox-lable {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-left: 8px;
}

.cashflow-report-calc-height {
  height: calc(100vh - 470px);
}

@media (max-width: 992px) {
  .cashflow-report-calc-height {
    height: calc(100vh - 350px);
  }
  .cash-flow-table-calc-height {
    height: calc(100vh - 320px) !important;
  }
  .cash-flow-crop-table-calc-height {
    height: calc(100vh - 320px) !important;
  }
}

.overflowX-hidden {
  overflow-x: hidden;
}

.calc-height-for-popup {
  max-height: calc(100vh - 100px) !important;
}

.active-icon-color {
  color: #22C687 !important;
}

.active-border {
  border: 1px solid #22C687 !important;
}

.active-text-color {
  color: #22C687;
}

.short-option-style {
  margin-left: 8px;
}

@media(max-width: 1084px) {
  .map-space {
    margin-top: 73px;
  }
}

.sa-add-farmer-full-bg {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.farmer-add-full {
  max-height: calc(100vh);
  overflow-y: scroll;
}

.p-lr-24 {
  padding: 0px 24px 0px 24px;
}

.bg-img-none {
  background-image: none;
}

.overflow-auto {
  overflow: auto;
}

.quantity-m {
  margin-left: 10px;
}

.dotted-line-for-sign {
  float: right;
  text-align: center;
  width: 500px;
  border-top: dotted 1px black;
}

.transport-img-p {
  padding-left: 22px;
  padding-right: 8px;
  padding-top: 10px;
}


.toggle-layout-select {
  margin-top: -55px;
  margin-right: 60px !important;
  float: right;
}

.w-10 {
  width: 10px;
}

.dotted {
  border-style: dashed;
  border-color: black;
}

.hr-line {
  border-width: 2px;
  border-color: black;
}

.display-none {
  display: none !important;
}

.evaluation-value {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.evaluation-label {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;
}

.evaluation-questionnaire-label {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #324054;
  text-transform: capitalize;
  position: inherit;
}

.evaluation-remarks {
  color: #324054;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
}

.evaluation-remarks-value {
  font: normal normal normal 12px/18px Poppins;
  color: #4D4D4D;
}

.font-size-18 {
  font-size: 18px;
}


.single-card-content-export-csv {
  margin-top: 10px;
  min-height: 100px;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED !important;
  opacity: 1;
  transform: translate3d(-58%, 34px, 0px);
  margin-left: -49px;
}

.sa-table-count {
  padding-left: 6px !important;
  display: table-cell;
  vertical-align: middle;
}

.irrigation-mode-input {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  z-index: 998;
  background: var(--white) 0% 0% no-repeat padding-box !important;
  height: 70%;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  opacity: 1;
  margin: 0px auto;
  overflow: auto;
}

.popup-container .sa-modal-box-style {
  width: 100%;
  max-width: none;
  height: 100%;
}

.sa-popup-bg-style  {
  position: fixed;
  display: table;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 998;
}

.popup-container .sa-popup-child {
  padding: 0px 16px 0px 16px;
}

.legends-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legend-color {
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.legend-label {
  font-size: 14px;
  color: #757575;
}

.content-view .view-data {
  height: 44px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #F9F9F9;
  margin-bottom: 10px;
  border: none;
}

@media (max-width: 768px) {
  .legend-item {
    margin-right: 10px;
  }
  .legend-label {
    font-size: 12px;
  }

  .popup-container {
    width: calc(100% - 24px);
    height: auto;
  }

  .popup-container .sa-modal-box-style {
    width: calc(100% - 24px);
    max-width: none;
    height: 100%;
  }

  .content-view {
    width: 100%;
  }
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
}

.gm-style-iw-ch{
  display: none !important;
}

.gm-style-iw-d{
  margin: auto!important;
}
.farm-label-box{
  margin-top: 7px;
  box-shadow: none;
}

.fpo-mail {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.mail-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 40px;
  align-items: center;
  padding-bottom: 20px;
}
.mail-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.mail-decline-btn {
  background-color: #FF4343;
  color: #fff;
  width: 100%;
}
.mail-confirm-btn {
  background-color: #02C170;
  color: #fff;
  width: 100%;
}

.fpo-mail .check-circle {
  width: 30px;
  height: 30px;
}

.disable-btn {
  background-color: #EDEDED;
  color: #707070;
}

.view-dot {
  width: 14px;
  height: 14px;
  font-size: 11px;
  border-radius: 100%;
  background-color: red;
  color: white;
  right: 0;
  text-align: center;
}

.table-whole {
  display: flex;
  flex-direction: column;
  margin: 10px 0px 0px 0px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
  width: 100%;
}

.table-whole p {
  font-size: 14px;
  color: #22C687;
  margin: 0px 0px 0px 10px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.interest-line {
  border: 1px solid #22C687;
  margin: 10px;
  width: 100%;
  display: block;
}

.not-interest-line {
  border: 1px solid red;
  margin: 10px;
  width: 80%;
  display: block;
}

.ccr-call-details {
  display: flex;
  flex-direction: column;
  margin: 10px 0px 0px 0px;
  border-radius: 5px;
  width: 100%;
}

.disable-view-data {
  height: 44px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #F9F9F9 !important;
  margin-bottom: 10px;
  border: none;
}

.tab-container-ccr a {
  flex-grow: 1 !important;
  width: 100% !important;
}

.tab-container-ccr .farm-header-toggles {
  width: 100%;
}

.view-dot {
  width: 14px;
  height: 14px;
  font-size: 11px;
  border-radius: 100%;
  background-color: #02C170;
  color: white;
  text-align: center;
  display: none;
  margin: -10px -25px 0px 10px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1px;
}

.view-dot.visible {
  display: inline-block; 
}

.table-whole {
  display: flex;
  flex-direction: column;
  margin: 10px 0px 0px 0px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
  width: 100%;
}

.table-whole p {
  font-size: 14px;
  color: #22C687;
  margin: 10px 0px 0px 10px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.interest-line {
  border: 1px solid #22C687;
  margin: 10px;
  width: 100%;
  display: block;
}

.not-interest-line {
  border: 1px solid red;
  margin: 10px;
  width: 80%;
  display: block;
}

.ccr-call-details {
  display: flex;
  flex-direction: column;
  margin: 10px 0px 0px 0px;
  border-radius: 5px;
  width: 100%;
}

.disable-view-data {
  height: 44px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #F9F9F9 !important;
  margin-bottom: 10px;
  border: none;
}

.list-container {
  display: flex;
  padding: 10px;
  background: #F9F9F9;
  border-radius: 10px;
  width: 100%;
  gap: 10px;
}

.list-container .single-card {
  width: 20%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.list-container .heading {
  font-size: 14px;
}

.list-container .card-header-text {
  display: flex;
  align-items: center;
  margin: 5px;
}

.order-details {
  padding: 0px 20px 20px 20px;
  background-color: #ffff;
  display: flex;
    justify-content: center;
}

.order-details ul {
  color: #1E1E1E;
  font-size: 14px;
}

.order-details h1 {
  color: #02C170;
  font-size: 14px;
  font-weight: bold;
}
.order-details-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.activeTabStyle {
  border-bottom: solid 2px #22C687;
}

.nutrient-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.nutrient-selection button {
  background-color: #fff;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 0.23999999463558197px solid #EDEDED;
}

.nutrient-selection button.selected {
  background-color: #22C687 ;
  color: white;
}

.weather-box-icon {
  padding: 14px;
  height:44px !important;
  width: 44px !important;
}
.sticky-sa-popup-header {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
  margin-bottom: 8px;
}

.graph-box {
  margin: 10px 20px;
}

.tooltip-icon {
  border: 0;
  background: none;
  margin-left: 5px;
  cursor: help;
}
.location-container {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.location-text {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.location-text:hover {
  white-space: normal;
  max-width: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.sticky-search-container {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  padding: 10px 0;
}
.custom-text{
  font-size: 12px;
}
.feather{
  width: 20px;
  height: 20px;
}
.labelCustom{
  margin-bottom: 0px;
}
.alertLevel-text{
  margin-bottom: 3px;
  margin-left:3px;
}
.water-resource-view-icon{
  padding-left: 12px !important;
}
.custom-form{
  margin-top: 10px;
}
.farmer-count{
  font-size: 14px;
  display: flex;
  text-wrap: nowrap;
}
@media (max-width: 768px) {
  .farmers-count-container {
    display: inline-block;
  }
}
.farmers-count-container {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 5px;
  margin-right: 8px;
}
.farmer-icon {
  margin-bottom: 4px;
}

.time-range-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 16px;
  justify-content: space-between;
}

.time-range-filter .sa-popup-secondary-btn-style {
  margin-left: 16px;
}

.tog-export-btn{
  background:none !important;
  border-radius: 4px !important;
  border: none;
  opacity: 1;
  height: 36px;
  color: #aaa !important;
  font-size: 14px !important;
}
.tog-export-btn:hover{
  border: 1px solid #aaa !important
}
.btn-weather-icon {
  width: 48px !important;
  height: 48px !important;
}

.custom-typeahead .form-control {
  height: 32px !important;
  line-height: 30px !important;
  cursor: pointer;
}
.custom-typeahead {
  border: none !important;
}
.filter-wrapper .sa-filter {
  margin-left: 0;
  margin-top: 0;
}

.icon-size{
  width: 8px;
  height: 8px;
}

.border-bottom-green{
  border-bottom: 2px solid #22C687;
}

.crop-calendar-title {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crop-calendar-title-text {
  font-size: 25px;
  font-weight: 900;
}

.add-crop-agronomy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #a3a3a3;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.add-crop-title-text {
  font-size: 20px;
  margin-top: 60px;
  display: block;
  font-weight: 900;
}

.sub-topic {
  font-size: 18px;
  font-weight: 900;
  display: flex;
  align-items: center;
}

.m-t-10 {
  margin-top: 10px;
}

.save-button,
.cancel-button {
  width: 120px;
  background-color: #22C687;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center; 
}

.cancel-button {
  background-color: white;
  color: #707070;
  border: 1px solid #707070;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 23px;
}

.custom-dropdown {
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  position: relative;
}

.custom-dropdown:focus {
  border: solid 1px #2196F3 !important;
  box-shadow: none !important;
}

.custom-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 230px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 10px;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.next-and-continue-button {
  width: auto;
  background-color: white;
  color: #22C687;
  border: 1px solid #22C687;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  text-align: center;
  align-content: center;
  gap: 15px;
}

.crop-calendar-body {
  margin-top: 60px;
  color: #707070;
}

.plus-icon {
  font-size: 20px;
  color: #22C687;
  cursor: pointer;
  background-color: rgba(34, 198, 135, 0.07);
}

.crop-image {
  padding: 8px;
  opacity: 0.5;
  width: 35px;
  height: 35px;
  background-color: rgba(112, 112, 112, 0.3);
  border: 'none';
  border-radius: 20%
}

.crop-calendar-card {
  margin-top: 20px;
  width: 100%;
  padding: 30px 15px;
  border: 'none';
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.crop-calendar-grid {
  display: grid;
  grid-template-columns: 18% 82%;
  overflow: hidden;
  margin-top: 20px;
}

.grid-border {
  border: 1px solid rgba(0, 0, 0, 0.03);
}

.upload-img-crop-calendar {
  @extend .upload-img-farmer;
  border: none;
  padding: 5px;
}

.flex-between {
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center !important;
}

.justify-start {
  display: flex;
  justify-content: flex-start !important;
}

.justify-end {
  display: flex;
  justify-content: flex-end !important;
}

.table-th, .table-td {
  font-size: 15px;
  font-weight: 100;
  padding: 10px;
  text-align: center;
}

.table-th {
  font-size: 15px;
  font-weight: 900;
}

.table-t {
  border: 1px solid rgba(0, 0, 0, 1);
  table-layout: fixed;
}

.table-tr {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.table-tr-bg {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
}

.p-40 {
  padding: 40px !important;
}
.h-fit {
  height: fit-content;
}

.wrap-word{
  word-break: break-word;
}

.image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-20-30 {
  padding: 30px 30px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.f-black {
  color: #000000;
}

.f-white {
  color: #ffffff;
}

.p-10-20 {
  padding: 10px 20px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.round-corner {
  border-radius: 10px !important;
}

.round-corner-5 {
  border-radius: 5px !important;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.drop-shadow-bottom {
  box-shadow: 0 8px 10px -1px rgba(0, 0, 0, 0.01), 0 4px 6px -1px rgba(0, 0, 0, 0.06); 
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-20-30 {
  padding: 20px 30px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.word-wrap-hidden {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-15 {
  font-size: 15px !important;
}

.text-16{
  font-size: 16px !important;
}

.text-17 {
  font-size: 17px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-10 {
  font-size: 10px !important;
}

.custom-ul {
  list-style-type: disc;
  padding-left: 20px; 
  color: black; 
}

.custom-li {
  color: black;
}

.border-black {
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.p-1-05 {
  padding: 1px 7px !important;
}

.m-r-2 {
  margin-right: 10px !important
}

.image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.flex-between-justify {
  display: flex;
  justify-content: space-between;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-justify {
  display: flex;
  justify-content: center;
}

.gap-10 {
  gap: 10px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.crop-calendar-grid-header {
  display: grid;
  grid-template-rows: 10%;
  min-height: 350px;
}

.crop-calendar-grid-months {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: 900px;
}

.crop-calendar-grid-months-rows {
  display: grid;
  grid-template-rows: 10% 90%;
}

.crop-calendar-month-label {
  width: 325px;
  text-align: center;
}

.crop-calendar-weeks {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow: hidden;
}

.trash-icon-position {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  cursor: pointer;
}

.italic-text {
  font-style: italic;
}

.h-200 {
  height: 200px !important;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-row-2 {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.grid-row-3 {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.min-h-100 {
  min-height: 100px;
}

.italic-placeholder::placeholder {
  font-style: italic;
}

.p-t-100 {
  padding-top: 100px !important;
}
.farm-name {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fertilizer-title{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
}
.switch-wrapper {
  position: relative;
  display: inline-block;
}

.switch-wrapper .disable-indicator {
  position: absolute;
  top: -6px;
  right: -6px;
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.switch-wrapper.is-disabled:hover .disable-indicator {
  opacity: 1;
}

.sa-filter-multiselect{
  max-width: 320px;
}
.sa-filter-multiselect .dropdown-heading{
  max-width: 300px;
  height: 35px !important;
}
.rmsc {
  --rmsc-gray: #868DAA !important;
}
